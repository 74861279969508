.base-button {
  max-width: 100%;
  height: 3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text);
  font-size: 1.4rem;
  background-color: rgba(var(--color-text-rgb), 0.05);
  border: 0;
  border-radius: var(--border-radius);
  box-shadow: none;
  padding: 0 15px;
  text-align: center;
  transition: 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  transition-property: color, background-color, border-color;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: rgba(var(--color-text-rgb), 0.1);
  }

  // as Nav-button
  &.nav {
    font-size: 1.6rem;
  }
  &.nav-active {
    color: var(--color-text);
    background-color: transparent;
    box-shadow: none;
    font-weight: var(--fontWeightBold);
    border-bottom: 4px solid var(--color-primary);
    border-top-left-radius: 4px;
    padding: 20px 8px;
  }

  // appearance: primary
  &.primary {
    color: var(--color-white);
    background-color: var(--color-primary);
    box-shadow: rgba(var(--color-shadow-rgb), 0.1) 0px 0px 0px 1px inset,
      rgba(var(--color-shadow-rgb), 0.1) 0px 1px 2px;
    font-weight: var(--fontWeightMedium);
  }
  &.primary:hover {
    background-color: var(--color-primary-hover);
  }

  // appearance: outline
  &.outline {
    color: var(--color-primary);
    background-color: var(--color-white);
    box-shadow: inset 0 0 0 2px var(--color-primary);
    font-weight: var(--fontWeightMedium);
  }
  &.outline:hover {
    background-color: var(--color-primary);
    color: var(--color-white);
  }

  &.default {
    background: var(--color-text);
    color: var(--color-white);
  }
  &.default:hover {
    background-color: var(--color-primary);
  }

  &.plain {
    background-color: transparent;
    border-bottom: 4px solid transparent;
    border-top-left-radius: 4px;
    padding: 20px 8px;
  }
  &.plain:hover {
    border-bottom: 4px solid var(--color-primary);
  }
  &.plain.isSelected {
    font-weight: var(--fontWeightMedium);
    border-bottom: 4px solid var(--color-primary);
    color: inherit;
    background: none;
  }

  &.stroked {
    color: var(--color-text);
    background-color: transparent;
    border: 1px solid rgba(var(--color-text-rgb), 0.1);
  }
  &.stroked:hover {
    background-color: rgba(var(--color-text-rgb), 0.06);
  }

  &.subtle {
    color: var(--color-primary);
    background-color: transparent;
  }
  &.subtle:hover {
    color: var(--color-primary-hover);
  }

  &.warning {
    color: var(--color-white);
    background-color: var(--orange);
  }
  &.warning:hover {
    background-color: var(--darkOrange);
  }

  &.danger {
    color: var(--color-white);
    background-color: var(--red);
  }
  &.danger:hover {
    background-color: var(--darkRed);
  }

  // hide icon before/after and label when button is-loading
  &.isLoading {
    cursor: default;
  }

  // button is-selected
  &.isSelected {
    color: var(--color-white);
    background-color: var(--color-text);
    font-weight: var(--fontWeightMedium);
  }
  &.isSelected:hover {
    background-color: hsl(204, 100%, 6%);
  }

  // button should fit the immediate parent container
  &.fullWidth,
  &.fullWidth > span {
    width: 100%;
  }

  // button is-disabled
  &.isDisabled {
    color: rgba(var(--color-text-rgb), 0.2) !important;
    background: rgba(var(--color-text-rgb), 0.06) !important;
    border: 1px solid rgba(var(--color-text-rgb), 0.03) !important;
    box-shadow: none !important;
    pointer-events: none !important;
  }
}

.appearance-none {
  appearance: none;
}

// cursors
.cursor-auto {
  cursor: auto;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.cursor-pointer {
  cursor: pointer;
}

// pointer events
.pointer-events-all {
  pointer-events: all;
}
.pointer-events-auto {
  pointer-events: auto;
}
.pointer-events-none {
  pointer-events: none;
}

// user select
.user-select-all {
  user-select: all;
}
.user-select-auto {
  user-select: auto;
}
.user-select-none {
  user-select: none;
}
.user-select-text {
  user-select: text;
}

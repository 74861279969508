#zone-viz-options {
  position: absolute !important;
  top: 25px !important;
  right: 0px !important;
  z-index: 1 !important;
}

#zone-legend {
  position: absolute;
  right: 0px;

  height: 220px;
  bottom: 0px;
  width: 175px;
  z-index: 10;
  border: 1px solid black;
  background: white;
  .legend-title {
    background: white;
    color: black;
  }

  .legend-item h6 {
    width: 140px;
  }
}
#zonecontainer.options_visible {
  padding-right: 245px;
}

.cr.color input {
  margin: 0 !important;
  padding: 0 !important;
}
#zonecontainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: white;
  color: black;
  filter: blur(10);
  .vis-toolbar {
    position: fixed;
    background: white;
    z-index: 1;
    box-shadow: var(--shadow-popup);
    left: 0;
    right: 0;
    height: 25px;
    font-size: 12px;
    display: flex;
    align-items: center;
    .vis-options {
      align-items: center;
      display: flex;
    }
  }

  .guiContainer {
    position: absolute;
    top: 0;
    right: 0;
  }
  #closeButton {
    position: absolute;
    right: 0px;
    top: 1px;
    z-index: 25;
    padding: 2px;
    width: 25px;
    border-radius: 0;
    background: rgba(41, 146, 193, 0.76);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    border: 1px solid rgba(41, 146, 193, 0.76);
    margin-top: -1px;

    i {
      font-size: 10px;
      color: white;
    }
  }
  #showOptions {
    margin-left: 10px;
  }

  #zonevis {
    background: transparent;
    position: relative;
  }
}

.logo-container {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  &:hover {
    span {
      color: var(--color-primary-hover);
    }
    svg {
      opacity: 0.8;
    }
  }
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  img,
  .avatar {
    width: 102px;
    height: 102px;
    margin: 0;
    border-radius: 50%;
  }
  span {
    color: var(--color-primary);
    transition: color var(--duration);
  }
  svg {
    color: #fff;
    opacity: 1;
    transition: opacity var(--duration);
  }
  .input-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .placeholder-bg {
      background: #a2aaad;
      border-radius: 50%;
      width: 102px;
      height: 102px;
    }
    .img-circle-bg {
      width: 112px;
      height: 112px;
      border-radius: 50%;
      padding: 17px;
      border: 1px solid #d9e1e2;
    }
    .img-logo {
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
    svg {
      padding: 15px;
    }
    .select-label {
      margin-top: 12px;
      font-weight: var(--fontWeightMedium);
    }
    .input-hover {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      color: var(--color-white);
      font-weight: var(--fontWeightMedium);
      border-radius: 50%;
      width: 102px;
      height: 102px;
      margin: 0 auto;
      background: rgba(30, 32, 41, 0.8);
      transition: opacity var(--duration);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &:hover {
      .input-hover {
        opacity: 1;
      }
    }
  }
}

.legend-item {
  margin: 0 5px;
  font-size: 1.3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 0 12px;
  height: 100%;
  cursor: default;
  overflow: hidden;

  .color-rect {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    display: inline-block;
    border-radius: 2px;
    border-color: white;
    cursor: pointer;

    .lock-icon {
      color: #fff;
      opacity: 0;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: var(--duration) opacity;
      .MuiSvgIcon-root {
        font-size: 1.6rem;
      }
      svg {
        transform: rotateY(180deg);
      }
    }
    &:hover {
      .lock-icon {
        opacity: 1;
      }
    }
  }

  .color-rect.color-locked {
    .lock-icon {
      opacity: 1;
    }
  }
  .legend-item-count {
    font-family: var(--fontMono);
  }

  .legend-item-title {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 30px;
  }
}

.legend-item.active,
.legend-item:hover,
.legend-item.selected:hover {
  color: var(--color-text-hover);
  background-color: #dbdbdb;
}

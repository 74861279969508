.multiphase-axis-lock {
  color: #a2aaad;
  transition: all var(--duration);
  cursor: pointer;

  &:hover {
    color: #041c2c;
  }
  &.isLocked {
    color: #00c7b1;
    &:hover {
      color: #041c2c;
    }
  }
}

.RadioTabs {
  width: 100%;
  display: flex;
  gap: 18px;
  .RadioTab {
    cursor: pointer;
    width: 175px;
    border: 1px solid var(--color-shadow);
    border-radius: 4px;
    background-color: var(--color-white);
    font-size: 1.6rem;
    color: var(--color-text);
    transition: background-color var(--duration);
    .radio-container {
      display: flex;
      height: 46px;
      padding: 18px;
      align-items: center;
      input {
        opacity: 0;
        width: 0;
      }
      .circle {
        height: 18px;
        width: 18px;
        margin-right: 19px;
        border: 1px solid var(--color-shadow);
        background-color: var(--color-white);
        border-radius: 50%;
      }
    }
    &.isChecked,
    &:hover {
      background-color: var(--color-primary);
      color: var(--color-white);
      .radio-container {
        .circle {
          height: 16px;
          width: 16px;
          border: 3px solid var(--color-white);
          background-color: var(--color-primary);
        }
      }
    }
    &.isDisabled {
      background-color: rgba(var(--color-shadow-rgb), 0.5);
      border: 1px solid rgba(var(--color-shadow-rgb), 0.5);
      color: #a2aaad;
      pointer-events: none;
    }
  }
}

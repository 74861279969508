.UserProfileHeader {
  padding: var(--space-2) 0;

  .avatar {
    margin-right: 8px;
  }

  .name {
    color: var(--color-primary-rgb);
    font-weight: bold;
  }

  .email {
    color: rgba(var(--color-primary-rgb), 0.6);
    font-size: 1.2rem;
  }
}

.aurora-login {
  min-height: 100%;
  display: grid;
  grid-template-areas:
    "header"
    "body"
    "footer";
  grid-template-rows: auto auto auto;
  justify-content: center;
  align-items: center;
  background-image: url(../assets/bg-onboarding.svg);
  background-size: cover;
  .container {
    grid-area: body;
    margin: 0 auto;
    height: auto;
    .child-container {
      height: auto;
      max-height: 780px;
      display: grid;
      grid-template-rows: minmax(0, 1fr);
      justify-content: center;
      align-items: center;
      .child-wrapper {
        height: 100%;
        width: 60rem;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        position: relative;
      }
    }
  }
  .logo {
    grid-area: header;
    position: relative;
    align-self: flex-end;
  }
  .MuiPaper-root {
    border-radius: 0px;
    color: var(--color-text);
  }
  .MuiCardContent-root {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

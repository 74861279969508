.predicate-tree {
  .item-template {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .label {
      overflow: none;
      text-overflow: ellipsis;
      text-decoration: none;
      text-overflow: ellipsis;
      padding-right: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
    }
    &:hover {
      .icon-toggle {
        opacity: 1;
      }
    }
  }
  .show-count {
    padding-right: 0px;
  }
}

.avatar-container {
  position: relative;
  gap: 20px;
  align-items: center;
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img,
  .avatar {
    width: 102px;
    height: 102px;
    margin: 0;
    border-radius: 50%;
  }
  .avatar-initials {
    font-size: 5rem;
  }
  .input-container {
    cursor: pointer;
    .input-hover {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      color: var(--color-white);
      font-weight: var(--fontWeightMedium);
      border-radius: 50%;
      width: 102px;
      height: 102px;
      margin: 0 auto;
      background: rgba(30, 32, 41, 0.8);
      transition: opacity var(--duration);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &:hover {
      .input-hover {
        opacity: 1;
      }
    }
  }
}

.remove-avatar-dialog {
  .button {
    height: 48px;
    width: 120px;
    border-radius: 4px;
  }
  .button.danger {
    background-color: #ff5252;
  }
  .MuiTypography-root > div:first-child {
    font-size: 2.2rem;
    svg {
      color: #ff5252;
      margin-right: 15px;
    }
  }
}

.DropdownList {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  max-height: 26rem;
  box-shadow: var(--dropdownListShadow);
  border-radius: var(--border-radius);
  z-index: inherit;
  overflow: hidden;

  /* empty message */
  .empty-message {
    color: var(--silver);
    padding: var(--space-2) var(--space-4);
  }

  .list {
    max-height: 100%;
    list-style: none;
    flex-grow: 1;
    display: grid;
    padding: var(--space-2) 10px;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  /* list-option */
  .option {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 20px;
    grid-gap: var(--space-8);
    align-items: center;
    color: var(--colorDropdownItemDefault);
    border-radius: var(--border-radius);
    padding: 5px var(--space-3);
    font-weight: var(--fontWeightMedium);
    white-space: nowrap;
    cursor: pointer;
  }
  .option:hover {
    color: var(--colorDropdownItemHover);
    background-color: var(--colorDropdownItemHoverBackground);
  }

  /* when option is selcted */
  .option.selected {
    color: var(--colorDropdownItemHover);
    background-color: var(--colorDropdownItemHoverBackground);
  }

  /* dot transition when mouse is over an option */
  .dot {
    transition: stroke-width var(--duration-short) ease-out;
  }
  .option:hover .dot {
    stroke-width: 20;
  }
}

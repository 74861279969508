.chart-wrapper {
  .BaseSlideToggle.classic {
    --ringSize: 13px;
  }

  .heading,
  .chart-toolbar,
  .chart-axis {
    z-index: var(--index-1);
  }

  .title {
    color: rgba(var(--color-text-rgb), 0.6);
    line-height: var(--chart-toolbar-height);
  }
}

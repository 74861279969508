.BaseDualThumbRangeSlider {
  --thumb-size: 24px;
  --progress-lower: ;
  --progress-upper: ;
  --output-factor-lower: 0.5;
  --output-factor-upper: 0.5;
  --selected-track: var(--mcd-blue);
  --unselected-track: var(--silver);
  --gradient: var(--unselected-track, transparent) 0%,
    var(--unselected-track, transparent) var(--progress-lower),
    var(--selected-track, transparent) var(--progress-lower),
    var(--selected-track, transparent) var(--progress-upper),
    var(--unselected-track, transparent) var(--progress-upper),
    var(--unselected-track, transparent) 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-height: calc(var(--thumb-size) + var(--space-1));

  .label {
    width: 100%;
    color: var(--ink);
  }

  .prefix {
    margin-right: 0.8rem;
  }
  .suffix {
    min-width: 2.4rem;
    margin-left: 0.8rem;
    text-align: right;
  }

  .track-wrapper {
    flex: 1 1px;
    flex: 1 1px;
    align-items: center;
    min-height: calc(var(--thumb-size) + var(--space-1));
    cursor: pointer;

    &:focus {
      box-shadow: var(--shadow-focus);
    }
  }

  .track {
    flex: 1 1 auto;
    height: 6px;
    border-radius: 6px;
    background: linear-gradient(90deg, var(--gradient));
    margin: 6px 0;
  }

  .thumb {
    position: absolute;
    width: var(--thumb-size);
    height: var(--thumb-size);
    color: var(--silver);
    background: var(--color-white);
    border: 1px solid var(--silver);
    border-radius: 100%;
    box-shadow: var(--shadow-ring);
    transform: scale(1);
    transition-property: border-color, box-shadow, transform;
    transition-duration: var(--duration);
    transition-timing-function: var(--ease);
    -webkit-tap-highlight-color: transparent;
    cursor: -webkit-grab;

    &:hover,
    &:focus,
    &:active {
      box-shadow: var(--shadow-ring), var(--shadow-focus);
    }

    &:active {
      transform: scale(0.95);
      cursor: -webkit-grabbing;
    }
  }
  .thumb.lower {
    left: var(--progress-lower);
  }
  .thumb.upper {
    left: var(--progress-upper);
  }
  .thumb.focus {
    z-index: 21;
  }

  /* Slider output values */
  .output {
    position: absolute;
    bottom: 2.4rem;
    transition-property: opacity, visibility, transform;
    transition-duration: var(--duration);
    transition-timing-function: var(--ease);
    opacity: 0;
    pointer-events: none;
    visibility: hidden;

    span {
      min-width: 3.2rem;
      height: 3.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-white);
      background-color: var(--mcd-orange);
      border-radius: 3px;
      padding: 0 0.8rem;
    }
  }
  .output.lower {
    left: var(--progress-lower);
    transform: translate3d(calc(-50% + 1.2rem), -2px, 0);
  }
  .output.upper {
    left: var(--progress-upper);
    transform: translate3d(calc(-50% + 1.2rem), -2px, 0);
  }
  .thumb:hover + .output,
  .thumb:active + .output {
    visibility: visible;
    opacity: 1;
    transform: translate3d(calc(-50% + 1.2rem), -8px, 0);
  }
}

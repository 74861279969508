.OrganizationBilling {
  .organizationAccountHeader {
    margin-bottom: 40px;
    max-width: 900px;
    .BaseDropdown {
      height: 46px;
      font-size: 1.6rem;
      min-width: 205px;
    }
  }
  .table-container {
    max-width: 1000px;
    border: 2px solid #f3f3f4;
    background-color: #ffffff;
    margin-bottom: 40px;
    font-size: 1.6rem;
    padding: 32px 40px 26px 40px;
    .button {
      font-weight: var(--fontWeightMedium);
    }
  }

  table tr td.ant-table-selection-column {
    padding-left: 13px;
    padding-right: 0px;
  }
  .ant-table-thead > tr > th {
    background: #fff !important;
    border-bottom: none;
  }
  .ant-table-tbody > tr > td {
    border: none;
    background: #fff;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #fff !important;
    border-color: rgba(0, 0, 0, 0.03);
  }
  .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background: #f0f3f3 !important;
  }
  .ant-table-thead {
    .ant-table-cell {
      font-size: 1.4rem;
      color: var(--color-text);
      font-weight: var(--fontWeightBold);
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      font-size: 1.6rem;
      color: var(--color-text);
    }
  }

  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: var(--color-primary);
  }
}

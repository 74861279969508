.product-button,
.chart-type-button {
  width: 140px;
  background-color: #fff;
  justify-content: unset;
  border-radius: 4px;
  &:hover {
    background-color: #fff;
    border: 1px solid var(--color-primary);
  }
  & > span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .label {
      flex: 0 1 auto;
    }
    .after {
      margin-right: 12px;
    }
  }
}

.aurora-login {
  width: 100vw;

  .container {
    width: 79rem;
    gap: var(--space-3);
    padding: 3vh var(--space-6) 3vh;
    margin: 0 auto;
    position: relative;
    .content-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .heading {
        padding: var(--space-6);
      }
      button.primary,
      button.outline {
        font-size: 18px;
        width: 320px;
        padding: 30px 0;
      }
    }
  }

  input {
    height: 46px;
  }

  .container .login-form,
  .container .fieldset {
    width: 100%;
    display: grid;
    gap: 9px;
  }

  p {
    font-size: 1.4rem;
    color: rgba(var(--color-text-rgb), 0.6);
  }
  p a {
    color: var(--blue);
    margin-left: 8px;
  }
  p a:hover {
    color: var(--darkBlue);
  }

  .error-message {
    font-size: 1.2rem;
    color: var(--red);
    text-align: start;
    height: 21px;
  }
  .error-message p {
    color: var(--darkRed);
    margin-left: 6px;

    &::first-letter {
      text-transform: uppercase;
    }
  }
  .checkbox-input.checked .checkbox {
    border-color: var(--mcd-orange);
    background-color: var(--mcd-orange);
  }
  .forgot-password-btn {
    color: var(--color-accent);
  }
  .signup-row,
  .login-row {
    .sign-up-btn {
      text-decoration: underline;
    }
  }
}

// Component Transitions
.modal-enter-active .Modal__container,
.modal-leave-active .Modal__container {
  opacity: 1;
  transform: scale(1);
  visibility: visible;
  transition-delay: 0s;
}
.modal-enter .Modal__container,
.modal-leave-to .Modal__container {
  opacity: 0;
  transform: scale(0.9);
  visibility: hidden;
}

// Sidebar
.sidebar-enter-active,
.sidebar-leave-active {
  transform: translateX(0);
  transition: transform var(--duration) var(--ease);
}
.sidebar-enter,
.sidebar-leave-to {
  transform: translateX(calc(var(--sidebarWidth) * 1em + 0.8em));
}

// List
.list-enter-active,
.list-leave-active {
  transition: all var(--duration-long);
}
.list-leave-active {
  position: absolute;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(-0.4rem);
}
.list-move {
  transition: transform var(--duration-long);
}

// Generic Transitions
// Expand
.expand-enter-active,
.expand-leave-active {
  max-height: 200px;
}
.expand-enter,
.expand-leave-to {
  max-height: 0;
}

// Fade
.fade-enter-active,
.fade-leave-active {
  transition: opacity var(--duration);
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

// Slide-fade
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all var(--duration-short);
}
.slide-fade-enter,
.slide-fade-leave-to {
  min-height: 0;
  opacity: 0;
  overflow: hidden;
}

// Slide Up
.slide-up-enter,
.slide-up-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

// Slide Left
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all var(--duration) ease-out;
}
.slide-left-enter,
.slide-left-leave-to {
  opacity: 0;
  transform: translateX(100%);
}

// Zoom
.tick-enter-active,
.tick-leave-active {
  opacity: 1;
  transform: scale(1);
  transition-property: transform opacity;
  transition-duration: 1s;
  transition-timing-function: var(--ease-bounce);
  transition-delay: 0.1s;
}
.tick-enter,
.tick-leave-to {
  opacity: 0;
  transform: scale(1.5);
}

/* Notifications */
.notification-enter-active,
.notification-leave-active {
  opacity: 0.95;
}
.notification-leave-active {
  position: absolute;
}
.notification-enter,
.notification-leave-to {
  opacity: 0;
  transform: scale(0.95) translateY(10px);
}

.OrganizationSettingSideNav {
  height: calc(100vh - var(--navbar-height));
  .settings-nav {
    height: 100%;
    font-size: 1.6rem;
    padding: 40px 40px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    overflow-y: overlay;

    li.back-dashboard .button {
      svg {
        top: 5px;
        margin-right: 2px;
      }
    }

    .sub-heading,
    li.back-dashboard .button {
      color: rgba(var(--color-shadow-rgb), 0.5);
      font-weight: var(--fontWeightRegular);
      text-align: left;
      text-transform: none;
      font-size: 1.6rem;
      margin: 0;
    }
    li .button {
      margin: 0;
      padding: 0;
      .label {
        margin: 0;
      }
      &:hover {
        color: var(--color-primary);
      }
      svg {
        top: 5px;
        margin-right: 8px;
      }
      span > svg {
        font-size: 17px;
        top: 2px;
      }
    }
    .settings-section {
      display: grid;
      gap: 20px;
    }
    .settings-nav-item {
      button {
        width: 100%;
        min-height: 30px;
        font-size: 1.6rem;
        color: #b5b5b5;
        font-weight: var(--fontWeightRegular);
        &:hover {
          color: var(--color-primary);
        }
      }
      svg {
        top: 4px;
        margin-right: 10px;
      }
    }
    .settings-nav-item.active {
      .button {
        color: var(--color-primary);
      }
    }
  }
  ul {
    list-style: none;
    padding: 0;
  }
  .settings-section.feedback {
    margin-top: auto;
  }
}

.info-board:hover {
  background-color: rgba(var(--color-text-rgb), 0.05);
  .info {
    .settings-button {
      visibility: visible;
    }
  }
}

.info-board {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 5px;

  box-shadow: var(--shadowDrawer);
  .info {
    align-items: center;
    display: flex;
    text-align: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .header {
      font-size: 1.2rem;
      color: rgb(122, 122, 122);
    }
    .value {
      font-size: 1.6rem;
    }
    .settings-button {
      position: fixed;
      visibility: collapse;
      z-index: 2;
      width: 36px;
      height: 36px;
      right: 0;
      top: 0;
    }
    .info-vis {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
}

.BaseMenu {
  .settings {
    display: flex;
    padding: 10px 14px;
    flex-direction: column;
    gap: 3px;

    .settings-item {
      max-height: 30px;
      justify-content: space-between;
      .buttons {
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
      }
      .trigger-container {
        width: 100%;
        padding-bottom: 6px;
      }
      .field-button {
        width: 100%;
        border-radius: 4px;
      }

      label {
        min-width: 80px;
      }
      input {
        width: 100%;
      }
      display: inline-flex;
      flex-direction: row;
      align-items: center;
    }
    .settings-item:first-child {
      margin-bottom: 8px;
    }
  }
}

.checkbox-input {
  color: var(--color-text);
  margin: 0 var(--space-1);
  user-select: none;
  cursor: pointer;

  &:hover {
    color: var(--color-text);
  }
  &:hover .checkbox {
    background-color: rgba(var(--color-text-rgb), 0.06);
    border-color: rgba(var(--color-text-rgb), 0.4);
  }

  &:focus {
    box-shadow: var(--shadow-focus);
  }

  // checked state
  &.checked {
    color: var(--color-text);
  }
  // change box color
  &.checked .checkbox {
    border-color: var(--color-primary);
    background-color: var(--color-primary);
  }
  &.checked .checkbox.negate {
    border-color: var(--darkPink);
    background-color: var(--darkPink);
  }
  // show tick icon
  &.checked .checkbox .icon {
    transform: scale(1);
    opacity: 1;
    margin: 0;
  }

  /*
      hide checkbox input on top
      TODO: fix focus and hide implementation
    */
  .input {
    opacity: 0;
    cursor: pointer;
  }
  &.disabled .input {
    cursor: not-allowed;
  }

  .checkbox-label {
    margin-left: 8px;
    transition: color var(--duration);
    white-space: nowrap;
  }

  // square check-box
  .checkbox {
    width: 2rem;
    height: 2rem;
    background-color: transparent;
    border: 0.15rem solid rgba(var(--color-text-rgb), 0.4);
    border-radius: 3px;
    transition-property: background-color border-color;
    transition-duration: var(--duration);
    transition-timing-function: var(--ease);
  }

  // tick icon
  .checkbox .icon {
    color: var(--color-white);
    transition-property: transform opacity;
    transition-duration: var(--duration);
    transition-timing-function: var(--ease-bounce);
    transform: scale(0);
    opacity: 0;
  }

  // disable checkbox
  .checkbox.disabled {
    border-color: rgba(0, 0, 0, 0.15);
    background-color: #d5d5d5;
  }
}

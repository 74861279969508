/* Keep all Ant customizations here.
TODO: add directory and file structure for ant components and global theme */

/* root */
:root {
  --ant-primary-color: var(--color-primary) !important;
}

/* button */
.ant-btn:not(.ant-btn-circle) {
  border-radius: var(--border-radius);
}
.ant-btn.ant-btn-default:not(.ant-btn-dangerous):focus,
.ant-btn.ant-btn-default:not(.ant-btn-dangerous):hover {
  border-color: var(--color-primary);
}
.ant-btn.ant-btn-default:not(.ant-btn-dangerous):active {
  border-color: var(--color-primary-hover);
}

/* checkbox */
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: var(--border-radius);
}
.ant-select-tree-checkbox-inner,
.ant-checkbox-inner {
  width: 20px;
  height: 20px;
  border-color: rgba(var(--color-text-rgb), 0.4);
  border-radius: 0.3rem;
  border-width: 0.15rem;
}
.ant-select-tree-checkbox-inner:after,
.ant-checkbox-inner:after {
  /* shift tick mark to compensate for increased size */
  left: 23.5%;
}
.ant-checkbox-checked .ant-checkbox-inner:after {
  transform: rotate(45deg) scale(1.2) translate(-50%, -50%);
}
.ant-select-tree-checkbox:after,
.ant-checkbox-checked:after {
  border-color: var(--color-primary-hover);
}

/* drawer */
.ant-drawer-body {
  padding: 0;
}

/* input */
.ant-input-affix-wrapper {
  border-radius: var(--border-radius) !important;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: var(--color-primary);
}
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  border-color: var(--color-primary-hover);
  box-shadow: 0 0 0 2px rgba(var(--color-primary-rgb), 0.3);
}

/* select */
.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  padding-right: 24px;
}
/* .ant-select-disabled.ant-select:not(.ant-select-customize-input) > .ant-select-selector,
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.8);
} */
.ant-select-item.ant-select-item-option:hover {
  background-color: rgba(var(--color-primary-rgb), 0.1);
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--color-primary);
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled):hover {
  background-color: var(--color-primary-hover);
}

/* dropdown */
.ant-select-dropdown .ant-select-item-option-selected {
  color: var(--white);
}
.lasso-dropdown .ant-dropdown-menu {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 0;
  transform: translateX(100%) translateY(-3%);
}
.ant-dropdown .ant-dropdown-menu-item.ant-dropdown-menu-item-active {
  background-color: rgba(var(--color-text-rgb), 0.06);
}
.ant-dropdown .ant-dropdown-menu-item.ant-dropdown-menu-item-selected {
  background-color: var(--color-primary);
  color: var(--white);
  font-weight: 500;
}
.ant-dropdown .ant-dropdown-menu-item.ant-dropdown-menu-item-selected:hover {
  background-color: var(--color-primary-hover);
}

/* tree-select */
.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 56px;
}
.ant-select .ant-select-selection-item,
.ant-tree-select .ant-select-selection-item {
  font-weight: 500;
}
.ant-select,
.ant-tree-select {
  font-weight: 500;
  --ant-primary-color-hover: var(--color-primary-hover);
  --ant-primary-color-outline: rgba(var(--color-primary-rgb), 0.3);
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-tree-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--color-primary);
}
.ant-tree-select .ant-select-clear {
  top: calc(50% - 2px);
  right: 36px;
}
.ant-tree-select .ant-select-clear,
.ant-tree-select .ant-select-clear svg {
  width: 15px !important;
  height: 15px !important;
}

/* popovers */
.tab-popover.ant-popover {
  z-index: 9999;
}
.batch-options.ant-popover .ant-popover-inner-content,
.color-palette.ant-popover .ant-popover-inner-content,
.sort-options.ant-popover .ant-popover-inner-content,
.chart-bin-settings.ant-popover .ant-popover-inner-content,
.tab-popover.ant-popover .ant-popover-inner-content,
.folder-options.ant-popover .ant-popover-inner-content {
  padding: 0;
}

/* modal */
.ant-modal-content {
  border-radius: var(--border-radius);
}
.new-post-modal .ant-modal,
.edit-post-modal .ant-modal {
  width: 100% !important;
  max-width: min(1280px, calc(100vw - 32px));
}
.new-post-modal .ant-modal-body,
.edit-post-modal .ant-modal-body {
  max-height: calc(100vh - 96px);
  overflow: hidden overlay;
}
.new-post-modal .ant-modal-body,
.edit-post-modal .ant-modal-body {
  padding: 0;
}

/* react-data-grid custom sort icon */
.rdg .rdg-header-row .rdg-cell .rdg-header-sort-cell span:last-child {
  display: none;
}

/* Causes an issue where when changing colunm witdh in the react-data-grid the mouse icon to indicate the width can be changed appears in this box, instead of where the width of the colunms can be adjusted */
/*.rdg .rdg-header-row .rdg-cell::after {
  content: "";
  background: url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 15.713L18.01 9.70299L16.597 8.28799L12 12.888L7.40399 8.28799L5.98999 9.70199L12 15.713Z" fill="currentColor"/></svg>');
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  opacity: 0;
  transition: transform 0.15s, opacity 0.1s;
}*/

.rdg .rdg-header-row .rdg-cell[aria-sort="ascending"]::after {
  opacity: 1;
  transform: translateY(-50%) rotateX(180deg);
}
.rdg .rdg-header-row .rdg-cell[aria-sort="descending"]::after {
  opacity: 1;
}

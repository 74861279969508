.map-copy-overlay-container {
  position: absolute;
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  padding-right: 65px;
  padding-bottom: 60px;

  .copy-overlay {
    font-family: var(--fontSans);
    background-color: #2e48583d;
    border: 2px solid rgb(255, 208, 0);
    z-index: 1;
    pointer-events: none;
    display: grid;
    justify-content: center;
    grid-auto-flow: column;
    .icon-toggle {
      pointer-events: auto;
      color: #a2aaad;
      transition: color var(--duration);
      &:hover {
        color: var(--color-primary);
      }
    }
    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 6px;
      margin-top: 40px;
      .button {
        background: #fff;
        border-radius: 4px;
        padding: 10px;
        height: 36px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        cursor: pointer;
        pointer-events: auto;
        &:hover {
          .icon-toggle {
            color: var(--color-primary);
          }
          span {
            color: var(--color-primary);
          }
        }
        span {
          font-weight: var(--fontWeightBold);
          font-family: var(--fontSans);
          transition: color var(--duration);
        }
      }
    }
  }
}
.screenshot-options {
  min-height: 310px;
  border-radius: 4px;

  .separator {
    background-color: #d9e1e2;
    width: 100%;
    height: 1px;
  }
  .screenshot-title {
    padding: 12px 18px;
    font-weight: var(--fontWeightBold);
  }
  .input {
    height: 40px;
  }

  .editable-preset {
    padding: 0 17px;
    .input {
      width: 150px;
    }
    .BaseInput::after {
      content: " px";
      color: #a2aaad;
      font-size: 1.6rem;
      position: absolute;
      right: 35px;
      bottom: 8px;
    }
  }
  .modifiers {
    display: flex;
    flex-direction: column;
    padding: 13px 17px 20px 17px;
    gap: 20px;
    .label {
      font-weight: var(--fontWeightMedium);
      color: var(--color-text);
      font-size: 1.4rem;
    }
    .BaseInput .input {
      width: 155px;
    }
    .checkbox-label {
      color: #a2aaad;
      font-weight: var(--fontWeightMedium);
    }
  }
}

.DropdownList {
  .option {
    color: var(--color-text);
    .measurements {
      font-size: 1.2rem;
      color: #a2aaad;
    }
  }
}

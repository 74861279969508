.SettingsLayout {
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  width: 100%;
  height: 100%;
  .settings-content {
    background-color: var(--color-surface-02);
    display: grid;
    grid-template-columns: 320px minmax(0, 1fr);
    height: 100%;

    .settings-side-bar {
      background-color: var(--color-text);
    }
  }
}

.SettingsOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

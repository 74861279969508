.BaseMenu {
  position: fixed;
  max-width: calc(100% - var(--space-4));
  max-height: calc(100% - var(--space-4));
  background-color: var(--color-white);
  box-shadow: 0 1px 16px 0 rgba(45, 53, 63, 0.2);
  border-radius: var(--border-radius);
  margin-top: var(--space-1);
  transform: scale(0.96);
  transition: transform var(--duration-short), opacity var(--duration-short),
    visibility 0s linear var(--duration-short);
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;
  z-index: var(--index-popup);

  .content {
    overflow: hidden auto;
  }

  &.isOpen {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    transform: none;
    transition-delay: 0s, 0s;
  }

  /* Transform origin for place-align combination values */
  // Top placement
  &.top-start {
    transform-origin: bottom left;
  }
  &.top-center {
    transform-origin: bottom center;
  }
  &.top-end {
    transform-origin: bottom right;
  }

  // Bottom placements
  &.bottom-start {
    transform-origin: top left;
  }
  &.bottom-center {
    transform-origin: top center;
  }
  &.bottom-end {
    transform-origin: top right;
  }

  // Left placements
  &.left-start {
    transform-origin: top right;
  }
  &.left-center {
    transform-origin: center right;
  }
  &.left-end {
    transform-origin: bottom right;
  }

  // Right placements
  &.right-start {
    transform-origin: top left;
  }
  &.right-center {
    transform-origin: center left;
  }
  &.right-end {
    transform-origin: bottom left;
  }
}

.trigger-container {
  display: inline-flex;

  .label {
    // padding-bottom: 3px;
    display: block;
  }
}

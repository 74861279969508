.DataSource {
  max-width: 1200px;
  .description {
    font-size: 1.6rem;
    font-weight: var(--fontWeightMedium);
    max-width: 500px;
    padding-bottom: 20px;
  }
  .view-toggle {
    .icon-toggle {
      color: #a2aaad;
      transition: color var(--duration);
      font-size: 26px;
      padding: 0;
      &:hover,
      &.isActive {
        color: var(--color-primary);
      }
    }
  }
  .table-container {
    max-width: 800px;
    border: 2px solid #f3f3f4;
    background-color: #ffffff;
    margin-top: 30px;
    margin-bottom: 40px;
    font-size: 1.6rem;
    padding: 0px 40px 26px 40px;

    .priority-container {
      align-items: center;
      justify-content: center;
      .BaseTooltip {
        z-index: 0;
      }
      .priority-trigger {
        cursor: pointer;
        background: #041c2c;
        font-size: 1.3rem;
        font-weight: var(--fontWeightMedium);
        border-radius: 50%;
        color: #fff;
        width: 20px;
        height: 20px;
        margin-right: 15px;
        text-align: center;
      }
    }
  }
  table tr td.ant-table-selection-column {
    padding-left: 25px;
    padding-right: 0px;
  }
  .ant-table-thead > tr > th {
    background: #fff !important;
    border-bottom: none;
    text-align: center;
  }
  .ant-table-tbody > tr > td {
    border-top: 1px solid #f3f3f4;
    border-bottom: 1px solid #f3f3f4;
    background: #fff;
    padding: 15px 0;
  }
  .ant-table-tbody > tr > td:first-child {
    border-right: 1px solid #f3f3f4;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #fff !important;
    border-color: rgba(0, 0, 0, 0.03);
  }
  .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background: #f0f3f3 !important;
  }
  .ant-table-thead {
    .ant-table-cell {
      font-size: 1.6rem;
      color: var(--color-text);
      font-weight: var(--fontWeightBold);
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      font-size: 1.6rem;
      color: var(--color-text);
    }
  }
  .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
    box-shadow: none;
  }

  .grid-container {
    display: grid;
    grid-template-rows: minmax(0, 1fr);
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin-top: 30px;
    margin-bottom: 40px;
    gap: 20px;
    @media (max-width: 1100px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .source-category-item {
      padding: 15px 30px 10px 25px;
      border: 1px solid #f3f3f4;
      background-color: #ffffff;
      height: fit-content;
      .source-category-header {
        font-size: 1.6rem;
        font-weight: var(--fontWeightBold);
        padding-bottom: 10px;
        border-bottom: 1px solid #f3f3f4;
        margin-bottom: 16px;
      }
      .list-container {
        margin-bottom: 5px;
      }
      .list-order {
        font-size: 1.2rem;
        color: #d8d8d8;
        margin-right: 8px;
        width: 8px;
      }
      .source-container {
        border: 1px solid #f3f3f4;
        border-radius: 4px;
        align-items: center;
        padding: 5px 10px 5px 3px;
        .source-name {
          font-size: 1.4rem;
          color: var(--color-text);
          font-weight: var(--fontWeightBold);
        }
      }
      .source-category-sources > div:first-child {
        max-height: 390px;
        overflow-y: auto;
      }
    }
    .expand-container {
      margin-top: 6px;
      justify-content: center;
      svg {
        flex-shrink: 0;
      }
    }
  }
}

.BaseMenu {
  .priority-option {
    color: var(--color-text);
    cursor: pointer;
    padding: 2px 0px;
    transition: color var(--duration);
    &.isSelected {
      color: #9b9b9b;
    }
    &:hover {
      color: var(--color-primary);
    }
  }
}

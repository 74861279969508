.OrganizationSettingsLayout {
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  width: 100%;
  height: 100%;

  .settings-content {
    background-color: var(--color-surface-02);
    display: grid;
    grid-template-columns: 320px minmax(0, 1fr);
    height: 100%;

    .settings-side-bar {
      background-color: var(--color-text);
    }

    .organizationAccount {
      display: grid;
      grid-template-rows: auto minmax(0, 1fr);
      gap: 30px;
      text-align: left;
      padding: 32px 100px;
      width: 100%;
      min-width: 500px;
      margin: 0;
      overflow-y: auto;
      max-height: 100%;
      height: 100%;

      .organizationAccountHeader {
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }
  }
}

.SettingsOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.org-shapefiles {
  gap: 0px;
  width: 100%;
}
.ColorPicker {
  width: 18px;
  height: 18px;
  top: 4px;
}
.org-shapefiles label {
  margin-left: 5px;
  max-width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dropzone-container {
  margin: 0 12px 12px;
}

.dropzone {
  border: 1px dashed #a2aaad;
  cursor: pointer;
  border-radius: 4px;
  padding: 5px;
  opacity: 0.8;

  &.hasError {
    border: 1px dashed #2196f3;
  }
  &.dragActive {
    border-color: #00c7b1;
    color: #00c7b1;
  }
  transition: all var(--duration-short);
}

.dropzone:hover {
  border-color: #00c7b1;
  color: #00c7b1;
  background: rgba(0, 199, 177, 0.07);
}

.dropzone p {
  color: inherit;
}

/* TREE STYLE OVERRIDES */

/* general spacing */
.org-tree-container {
  padding: 2px 15px;
  max-height: 100%;
  overflow-y: auto;
  .ant-tree-draggable-icon {
    display: none !important;
  }
  .input {
    height: unset;
  }
  //   color: red;
  //     background: red;
  background-color: var(--color-surface-02);
}
.org-tree-container .ant-tree-node-content-wrapper {
  padding: 0;
  //   color: red;
  //     background: red;
  //     background-color: red;
}
.org-tree-container .ant-tree-list-holder-inner {
  padding: 0;
  // color: red;
  //   background: red;
  background-color: var(--color-surface-02);
}
.org-tree-container .ant-tree-node-content-wrapper label {
  margin: 0;
}
.org-tree-container .ant-tree-indent-unit {
  width: 19px;
}
.org-tree-container .ant-tree-treenode {
  padding: 0;
  margin-bottom: 4px;
  //   color: red;
  //     background: red;
  background-color: var(--color-surface-02);
}
.org-tree-container .ant-tree-treenode.filter-node {
  background-color: rgba(var(--color-primary-rgb), 0.4);
}

/* hover & selected styles */
.org-tree-container .ant-tree-treenode:hover {
  background: #f5f5f5;
}
.org-tree-container .ant-tree-treenode.ant-tree-treenode-selected {
  background: #eee;
}
.org-tree-container .ant-tree-node-content-wrapper {
  // color: red;
  // background: red;
  // background-color: red;
}
//         background-color: var(--color-surface-02);

/* checkbox */
.org-tree-container .ant-tree-checkbox-inner {
  width: 18px;
  height: 18px;
}
.org-tree-container .ant-tree-checkbox:hover .ant-tree-checkbox-inner {
  border-color: var(--color-primary);
}
.org-tree-container .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.org-tree-container .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  background-color: var(--color-primary);
  width: 6px;
  height: 6px;
}

/* replace open/close icon */
.org-tree-container .ant-tree-switcher {
  background-color: var(--color-surface-02);
}
.org-tree-container .ant-tree-switcher:hover::after {
  border-color: var(--color-primary);
}
.org-tree-container .ant-tree-switcher.ant-tree-switcher_open {
  transform: rotate(0deg);
}

.org-tree-container .ant-tree-switcher.ant-tree-switcher_close {
  transform: rotate(-90deg);
}
.org-tree-container .ant-tree-switcher svg {
  display: none;
}
.org-tree-container .ant-tree-switcher-noop {
  opacity: 0.25;
  pointer-events: none;
}
.org-tree-container .shapefile-leaf .ant-tree-switcher-noop {
  opacity: 0;
  width: 3px;
  pointer-events: none;
}
.org-tree-container .ant-tree-switcher::after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 8px;
  width: 7px;
  height: 7px;
  border: 1px solid var(--color-text);
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

/* adjust spacing */
.org-tree-container .trigger-container {
  width: unset;
  svg {
    display: inline-block;
    vertical-align: middle;
  }
}

/* remove lines */
.org-tree-container .ant-tree-switcher-leaf-line::before,
.org-tree-container .ant-tree-switcher-leaf-line::after,
.org-tree-container .ant-tree-indent-unit::before {
  display: none;
}

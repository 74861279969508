.node {
  backface-visibility: hidden;
  background-color: transparent;
  border-radius: var(--border-radius);
  color: rgba(var(--color-text-rgb), 0.9);
  display: flex;
  height: 26px;
  padding: 0px 0px;
  transition: background-color var(--duration) ease-out;
  user-select: none;

  &.focused {
    outline: var(--outline);
    outline-offset: calc(-1 * var(--outline-width));
  }

  &.hovered {
    background-color: #f5f5f5;
  }

  // when folder node is toggled open
  &.isOpen.isFolder {
    color: var(--color-text);
  }

  &.isOpen .folder-toggle {
    transform: rotate(90deg);
  }

  // bold font for folder node
  &.isFolder {
    font-weight: var(--fontWeightMedium);
  }

  // selected
  &.selected.isLeaf {
    background-color: #f5f5f5;
    color: var(--color-primary);
    font-weight: var(--fontWeightMedium);
  }
}
.node:hover {
  background-color: #f5f5f5;
}
.no-list-style.tree-branch {
  border-left: 1px dashed gray;
}
// folder toggle icon
.folder-toggle {
  border-radius: var(--border-radius);
  height: 24px;
  margin-right: 8px;
  transition: transform var(--duration) ease-out 0s,
    background-color var(--duration) ease-in 0s;
  width: 24px;
}

// entity status icon
.icon.pending,
.icon.approved {
  margin-right: 6px;
}

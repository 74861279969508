.radio-input {
  color: var(--color-text);
  margin: 0 var(--space-1);
  user-select: none;
  cursor: pointer;

  &:hover .radio {
    border-color: var(--color-primary);
  }

  &:focus {
    box-shadow: var(--shadow-focus);
  }

  &.checked .radio {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      border-radius: 50%;
      border: 2px solid var(--color-white);
      background-color: var(--color-primary);
    }
  }
  // show tick icon
  &.checked .radio .icon {
    transform: scale(1);
    opacity: 1;
  }

  /*
      Hide radio input on top
      TODO: fix hide implementation
    */
  .input {
    opacity: 0;
    cursor: pointer;
  }

  .radio-label {
    margin-left: 8px;
    transition: color var(--duration);
    white-space: nowrap;
    &.active {
      color: var(--color-text);
      font-weight: var(--fontWeightBold);
    }
  }

  // radio-circle
  .radio {
    width: 1.8rem;
    height: 1.8rem;
    background-color: var(--transparent);
    border: 1px solid rgba(var(--color-text-rgb), 0.4);
    border-radius: 9999px;
    transition-property: background-color, border-color;
    transition-duration: var(--duration);
    transition-timing-function: var(--ease);
    &:before {
      content: "";
      border-radius: 50%;
      background-color: inherit;
      transition: background-color var(--duration);
    }
  }

  // tick icon
}

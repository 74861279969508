.steps-container {
  display: flex;
  .step-child {
    flex: 1 1 auto;
    text-align: center;
    font-size: 20px;
    font-weight: var(--fontWeightBold);
    color: #d9e1e2;
    border-bottom: 2px solid #d9e1e2;
    position: relative;
    span {
      width: 100%;
      padding-bottom: 15px;
    }
    &.active,
    &.past {
      border-bottom: 2px solid #e09868;
    }
    &.active {
      color: #e09868;
    }
    &.active:after,
    &.past:after {
      content: "";
      position: absolute;
      bottom: -4px;
      right: 0;
      width: 7px;
      height: 7px;
      border-radius: 4px;
      background-color: #e09868;
    }
    &.clickable {
      cursor: pointer;
      color: var(--color-text);
    }
  }
}

.BaseSlideToggle {
  --ringSize: 20px;
  --ringOffset: 2px;
  --toggleWidth: calc(2 * var(--ringSize) + 4px);
  --toggleHeight: 6px;
  width: var(--toggleWidth);
  min-width: var(--toggleWidth);
  height: var(--toggleHeight);
  border: 1px solid transparent;
  border-radius: 100rem;
  margin: 7px 0;
  background-color: var(--slideToggleDefaultColor);
  transition-duration: var(--duration);
  transition-property: box-shadow, background-color;

  /*
      classic toggle height
    */
  &.classic {
    --ringSize: 14px;
    --toggleWidth: calc(2.5 * var(--ringSize));
    --toggleHeight: calc(var(--ringSize) + 4px);
    margin: 0;
  }

  /* Checkbox: hidden on top */
  &.disabled {
    opacity: 0.5;
    .checkbox {
      cursor: not-allowed;
      opacity: 0;
    }
  }

  /* Checked state */
  &.checked {
    background-color: var(--color-primary);
  }
  /* Move slider-ring when toggle is checked */
  &.checked .slider-ring {
    transform: translate3d(
      calc(var(--toggleWidth) - var(--ringSize) - var(--ringOffset) - 1px),
      -50%,
      0
    );
  }

  /* Checkbox: hidden on top */
  .checkbox {
    width: var(--toggleWidth);
    height: var(--toggleHeight);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    padding: 0;
    opacity: 0;
    z-index: var(--index-1);
    pointer-events: all;
    cursor: pointer;
  }

  /* Toggle slider-ring */
  .slider-ring {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--ringSize);
    height: var(--ringSize);
    position: absolute;
    top: 50%;
    left: 0;
    color: var(--silver);
    background-color: var(--color-white);
    border-radius: var(--ringSize);
    box-shadow: var(--shadow-ring);
    transform-origin: center;
    transform: translate3d(var(--ringOffset), -50%, 0);
    transition-property: box-shadow, transform;
    transition-duration: var(--duration);
    transition-timing-function: var(--ease);

    &:after {
      content: "";
      top: 4px;
      width: 4px;
      height: 4px;
      border-radius: 4px;
      background-color: var(--slideToggleBackgroundColor);
    }
  }
}

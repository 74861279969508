// drawer background and overlay
.drawer-overlay {
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--duration), visibility 0s linear var(--duration);
  visibility: hidden;
  z-index: var(--index-drawer);
}
.drawer-overlay.open {
  opacity: 1;
  pointer-events: all;
  transform: none;
  transition-delay: 0s, 0s;
  visibility: visible;
}
.drawer-background {
  inset: 0px;
  background-color: rgba(var(--color-primary-rgb), 0.4);
}

// drawer
.drawer {
  --ease: cubic-bezier(0.77, 0, 0.175, 1);
  top: 0;
  height: 100vh;
  color: var(--color-text);
  background-color: var(--color-background);
  box-shadow: rgba(var(--color-shadow-rgb), 0.1) 0 0 12px 0,
    rgba(var(--color-shadow-rgb), 0.2) 0 10px 30px 0;
  // transform: translate3d(-101%, 0, 0);
  transition: transform var(--duration), opacity var(--duration),
    visibility 0s linear var(--duration);
  opacity: 0;
  visibility: hidden;

  // drawer positions
  &.left {
    left: 0;
    transform: translate3d(-101%, 0, 0);
  }
  &.right {
    right: 0;
    transform: translate3d(101%, 0, 0);
  }

  // drawer widths
  &.narrow {
    min-width: 36rem;
    max-width: 24vw;
  }
  &.medium {
    min-width: 48rem;
    max-width: 36vw;
  }
  &.wide {
    min-width: 60rem;
    max-width: 48vw;
  }
}
.drawer.open {
  opacity: 1;
  pointer-events: all;
  transform: translate3d(0, 0, 0);
  transition-delay: 0s, 0s;
  visibility: visible;
}

.drawer.left .drawer-action {
  left: 0;
}
.drawer.right .drawer-action {
  right: 0;
}
.drawer-action {
  position: absolute;
  top: 0;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 1000rem;
  margin: 1rem;
  z-index: var(--index-1);
  cursor: pointer;

  .icon {
    color: rgba(var(--color-text-rgb), 0.4);
    pointer-events: none;
  }
}
.drawer-action:hover {
  background-color: rgba(var(--color-text-rgb), 0.06);
}
.drawer-action:hover .icon {
  color: var(--color-primary);
}

// content
.drawer-content {
  flex: 1 1 0%;
  overflow: auto;
  padding-top: var(--space-5);
  padding-bottom: var(--space-5);
}
.drawer.left .drawer-content {
  padding-left: 6rem;
  padding-right: var(--space-3);
}
.drawer.right .drawer-content {
  padding-left: var(--space-3);
  padding-right: 6rem;
}

.password-container {
  & > svg {
    cursor: pointer;
    position: absolute;
    right: 16px;
    color: rgba(var(--color-text-rgb), 0.4);
    transition: color var(--duration);
    &:hover,
    &.not-visible {
      color: var(--color-text);
    }
  }
}

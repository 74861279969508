.BaseDropdown {
  gap: 6px;
  height: 3.2rem;
  color: var(--color-text);
  background-color: var(--color-white);
  border: 1px solid var(--smoke);
  border-radius: var(--border-radius);
  font-weight: var(--fontWeightMedium);
  padding: 0 var(--space-3);
  transition-property: color, background-color, box-shadow, transform, border;
  transition-duration: var(--duration);
  min-width: 100%;
  transition-timing-function: var(--ease);
  transform: scale(1);
  user-select: none;
  cursor: pointer;

  &:hover:not(:active),
  &:focus:not(:active) {
    border: 1px solid var(--color-primary);
  }

  /* When dropdown list isOpen  */
  &.isOpen {
    box-shadow: rgba(var(--color-text-rgb), 0.06) 0 1px 2px 0, var(--shadow-focus);
  }
  &.isOpen .icon-dropdown {
    transform: rotateX(-180deg);
  }

  &.has-errors {
    border: 1px solid var(--color-danger);
  }

  .error-message {
    font-size: 1.4rem;
    margin-top: 5px;
    color: var(--color-danger);
  }

  .inline-label {
    display: inline-block;
    color: rgba(var(--color-text-rgb), 0.6);
    font-weight: var(--fontWeightRegular);
    margin-right: var(--space-1);
  }

  // Loading and dropdown icon
  .icon {
    color: var(--slate);
  }
  .icon.caret {
    transform: rotateZ(0);
    transform-origin: center center;
    transition: transform var(--duration) var(--ease);
  }
  &.isOpen .icon.caret {
    transform: rotateZ(180deg);
  }

  // prevent text-wrap
  .button,
  .inline-label,
  .selected-option {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.readonly {
    color: grey;
  }
}

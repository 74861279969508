.userAccount {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 30px;
  text-align: left;
  padding: 32px 100px;
  width: 100%;
  min-width: 500px;
  overflow-y: auto;
  grid-template-columns: minmax(0, 1fr);
}
.userAccountHeader {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-top: 0px;
  margin-top: -10px;
  margin-bottom: 10px;
}

.profileForm,
.passwordForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  max-width: 455px;
  .label {
    font-size: 1.6rem;
    font-weight: var(--fontWeightMedium);
  }
  .input {
    height: 46px;
  }
  .trigger-container {
    width: 100%;
    .BaseDropdown {
      height: 46px;
    }
  }
  .button-container {
    display: inline-flex;
    justify-content: flex-start;
    width: 100%;
    .button {
      width: 320px;
      height: 54px;
      border-radius: 4px;
      span {
        font-size: 1.8rem;
        font-weight: var(--fontWeightBold);
      }
    }
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul li {
  display: grid;
}
ul li .base-button {
  justify-content: flex-start;
}

@import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Roboto+Mono:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

// TODO: delete unused fonts, move google-fonts import to index.html
// TODO: replace styles with 'antd' typography components

@font-face {
  font-family: "apercu";
  src: url("../fonts/apercu-light.woff") format("woff");
  font-weight: 200;
}

@font-face {
  font-family: "apercu";
  src: url("../fonts/apercu-regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "apercu";
  src: url("../fonts/apercu-medium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "apercu";
  src: url("../fonts/apercu-bold.woff") format("woff");
  font-weight: 700;
}

/*
  <Heading />
  defaults to h2
*/

h1 {
  font-size: 5.2rem;
}
h2 {
  font-size: 4.2rem;
}
h3 {
  font-size: 3.2rem;
}
h4 {
  font-size: 2.4rem;
}
h5 {
  font-size: 2rem;
}
h6,
.heading {
  color: var(--color-text);
  font-family: var(--fontSans);
  font-weight: var(--fontWeightBold);
  padding: 0;
  margin: 0;
}

/*
  <SubHeading />
  defaults to h3
*/

.sub-heading {
  color: rgba(var(--color-text), 0.6);
  font-size: 1.3rem;
  font-weight: var(--fontWeightBold);
  line-height: 1.6rem;
  text-transform: uppercase;
  margin: 0;

  @media screen and (min-width: 600px) {
    font-size: 1.4rem;
    line-height: 1.5rem;
  }
}

/*
  <Text />
  defaults to paragraph
*/
p {
  max-width: 60ch;
  color: var(--color-text);
  padding: 0;
  margin: 0;
}

code {
  color: var(--color-text);
  background-color: rgba(var(--color-text-rgb), 0.06);
  border-radius: var(--border-radius);
  line-height: 1.625;
  font-family: var(--fontMono);
  padding: var(--space-1);
}

/*
  <Caption />
  defaults to paragraph
*/
.caption {
  color: var(--color-text);
  font-size: 1.2rem;
  font-weight: var(--fontWeightRegular);
  line-height: 1.4rem;

  @media screen and (min-width: 600px) {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
}

/*
  <Link />
  defaults to anchor
*/
a,
.link {
  color: var(--color-text);
  text-decoration: none;

  &:hover {
    color: var(--color-primary);
  }
}

.three-d-viewer {
  .well-labels {
    font-size: 9px;
  }

  .three-d-scene {
    .main {
      position: absolute;
      right: 0;
      z-index: 1220;
    }
  }

  .ipdb-value-label-container {
    position: absolute;
    font-size: 10px;
    top: 0;
    left: 0;

    .value-label {
      position: absolute;
    }

    .min-label {
      left: 50px;
      top: 140px;
    }
    .title-label {
      width: 120px;
      left: 4px;
      top: 25px;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
    }

    .max-label {
      top: 44px;
      left: 50px;
    }
  }

  .dg {
    option {
      background-color: black;
    }
  }

  .dg .c select {
    margin-top: 0;
  }
}

.well-list {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 160px;
  height: 300px;
}

#well-label-container {
  color: #333333;
}

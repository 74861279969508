.ComponentPane {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .react-grid-layout {
    min-height: 100%;
    .chart-wrapper {
      border-bottom: 2px solid #f9f9f9;
      border-right: 2px solid #f9f9f9;
      overflow: hidden;
    }
  }

  .grid-item-wrapper {
    width: 100%;
    height: 100%;
  }
  .react-grid-item > .react-resizable-handle {
    position: absolute;
    z-index: 102;
    width: 20px;
    height: 20px;
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }
  .react-grid-item:not(.dropping) > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 8px;
    bottom: 8px;
    width: 10px;
    height: 10px;
    border-right: 3px solid var(--color-primary);
    border-bottom: 3px solid var(--color-primary);
  }
  .react-grid-item.react-grid-placeholder {
    background: var(--color-primary);
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  .react-contextmenu {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    color: #373a3c;
    font-size: 16px;
    margin: 2px 0 0;
    min-width: 160px;
    outline: none;
    opacity: 0;
    padding: 5px 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;
  }

  .react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
  }

  .react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: #373a3c;
    cursor: pointer;
    font-weight: 400;
    line-height: 1.5;
    padding: 3px 20px;
    text-align: inherit;
    white-space: nowrap;
  }

  .react-contextmenu-item.react-contextmenu-item--active,
  .react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
  }

  .react-contextmenu-item.react-contextmenu-item--disabled,
  .react-contextmenu-item.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
    color: #878a8c;
  }

  .react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;
  }
  .react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
  }

  .react-contextmenu-item.react-contextmenu-submenu {
    padding: 0;
  }

  .fullscreen {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: white;
    overflow: hidden;
    > div {
      width: 100%;
      height: 100%;
    }
    .ChartPlot.screenshot {
      min-height: auto;
      min-width: auto;
      max-width: none;
      max-height: none;
      position: relative;
      top: 60px;
      pointer-events: none;
    }
  }
}

.portal {
  > div {
    width: 100%;
    height: 100%;
  }
}

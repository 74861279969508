.OrganizationDisplaySettings {
  max-width: 560px;

  .section-heading {
    font-size: 1.6rem;
    padding-bottom: 22px;
    font-weight: var(--fontWeightMedium);
    padding: 0;
  }
  .current-preset {
    gap: 15px;
    padding: 20px 0 35px 0;
    .BaseInput {
      .label {
        font-size: 1.6rem;
        font-weight: var(--fontWeightMedium);
      }
    }
    .input {
      height: 46px;
      font-size: 1.6rem;
    }
    .title-input {
      width: 230px;
    }
    .width-input,
    .height-input {
      max-width: 110px;
      .input {
        text-align: right;
        padding: 5px;
        padding-right: 16px;
      }
    }
    .button.subtle {
      font-size: 1.6rem;
      font-weight: var(--fontWeightMedium);
      padding: 0;
      top: 35px;
      &.isDisabled {
        background: none !important;
        border: none !important;
      }
    }
  }
  .preset-list {
    font-size: 1.6rem;
    .screenshot-preset {
      cursor: pointer;
      gap: 14px;
      transition: background-color var(--duration);
      .title {
        width: 230px;
      }
      .width {
        padding-left: 22px;
        box-sizing: border-box;
        width: 110px;
      }
      .height {
        padding-left: 28px;
        box-sizing: border-box;
        width: 110px;
      }
      .title-input {
        width: 230px;
      }
      .width-input {
        width: 110px;
      }
      .height-input {
        width: 110px;
      }
      .action-buttons {
        flex: 15% 1;
        opacity: 0;
        color: #a2aaad;
        text-align: center;
        svg:first-child {
          top: 4px;
          &:hover {
            color: var(--color-primary);
            transition: color var(--duration);
          }
        }
        svg:last-child {
          top: -3px;
          &:hover {
            color: var(--color-danger);
            transition: color var(--duration);
          }
        }
      }
      .BaseInput {
        .input {
          height: 46px;
        }
      }
      .editing-actions svg {
        margin: 0 8px;
        &:hover {
          color: var(--color-primary);
          transition: color var(--duration);
        }
        &:last-child:hover {
          color: var(--color-danger);
          transition: color var(--duration);
        }
      }
    }
    .screenshot-preset.not-editing {
      padding: 3px 13px;
      min-height: 42px;
      &:hover {
        background-color: rgba(var(--color-shadow-rgb), 0.4);
        .action-buttons {
          opacity: 1;
        }
      }
      svg {
        margin: 0 0 0 8px;
      }
      .action-buttons {
        padding-left: 10px;
        box-sizing: border-box;
        width: 73px;
        transition: opacity var(--duration);
      }
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
}

@import "./classes/interactivity";

/*
  Invert color
*/
.invert {
  filter: invert(1) brightness(0) invert(1);
}

/*
  Hide scrollbars
*/
.hide-scrollbar {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
  }
}

/*
  Clip text
*/
.clip-text {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

/*
  TODO: add rest of the font-features from main.scss
  Open-type features
*/
.pnum {
  font-feature-settings: "pnum";
}

/*
  Text
*/
/* vertical align */
.valign-baseline {
  vertical-align: baseline;
}
.valign-top {
  vertical-align: top;
}
.valign-middle {
  vertical-align: middle;
}
.valign-bottom {
  vertical-align: bottom;
}
/* text align */
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}
/* case conversion */
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}
.no-case {
  text-transform: none !important;
}

/*
  Position
*/
.static {
  position: static;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}

/*
  absolute-center:
  center within its closest relatively positioned parent
*/
/* centers both horizontally and vertically */
.absolute-center {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* centers horizontally on the x axis */
.absolute-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
/* centers horizontally on the y axis */
.absolute-center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

/*
  absolute-spread:
  cover its closest relatively positioned parent
*/
.absolute-spread {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* position top, left, right, bottom */
.pin-topleft {
  top: 0;
  left: 0;
}
.pin-topright {
  top: 0;
  right: 0;
}
.pin-bottomleft {
  bottom: 0;
  left: 0;
}
.pin-bottomright {
  bottom: 0;
  right: 0;
}

/*
  Visually hide elements
*/
.visually-hidden {
  position: absolute !important;
  top: 0;
  clip: rect(1px, 1px, 1px, 1px) !important;
  overflow: hidden !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  border: 0 !important;
}
/*
  Visibility
*/
.visible {
  visibility: visible;
}
.hidden {
  visibility: hidden;
}
.fill {
  width: 100%;
  height: 100%;
}
.fill-width {
  width: 100%;
}
.fill-height {
  height: 100%;
}
.hide {
  opacity: 0;
  pointer-events: none;
}

/*
  Font
*/
.font-sans {
  font-family: var(--fontSans);
}
.font-serif {
  font-family: var(--fontSerif);
}
.font-mono {
  font-family: var(--fontMono);
}
.font-round {
  font-family: var(--fontRound);
}

.weight-light {
  font-weight: var(--fontWeightLight);
}
.weight-regular {
  font-weight: var(--fontWeightRegular);
}
.weight-medium {
  font-weight: var(--fontWeightMedium);
}
.weight-bold {
  font-weight: var(--fontWeightBold);
}
.weight-black {
  font-weight: var(--fontWeightBlack);
}

/*
  Flexbox
*/
.inline-flex {
  display: inline-flex;
}
.flex {
  display: flex;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-auto {
  flex: 1 1 auto;
}
.flex-shrink {
  flex-shrink: 1;
}
.flex-grow {
  flex-grow: 1;
}
.flex-auto-0 {
  flex: 0 0 auto;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-grow-0 {
  flex-grow: 0;
}

/* Inserting a collapsed row between two flex items will make
 * the flex item that comes after it break to a new row */
.break-row {
  flex-basis: 100%;
  height: 0;
}
/* Use a collapsed column to break to a new column */
.break-col {
  flex-basis: 100%;
  width: 0;
}

/* align items */
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.items-stretch {
  align-items: stretch;
}

/* align self */
.self-start {
  align-self: flex-start;
}
.self-end {
  align-self: flex-end;
}
.self-center {
  align-self: center;
}
.self-baseline {
  align-self: baseline;
}
.self-stretch {
  align-self: stretch;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
/* justify items */
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-around {
  justify-content: space-around;
}
.justify-between {
  justify-content: space-between;
}

/* align-content */
.content-start {
  align-content: flex-start;
}
.content-end {
  align-content: flex-end;
}
.content-center {
  align-content: center;
}
.content-around {
  align-content: space-around;
}
.content-between {
  align-content: space-between;
}
.content-stretch {
  align-content: stretch;
}

/* align and justify center */
.flex-centered {
  align-items: center;
  justify-content: center;
}

/* flex order */
.order-0 {
  order: 0 !important;
}
.order-1 {
  order: 1 !important;
}
.order-2 {
  order: 2 !important;
}
.order-3 {
  order: 3 !important;
}
.order-last {
  order: 99999999 !important;
}
.order-first {
  order: -1 !important;
}

/*
  Gap for both flexbox and grids
*/
.gap-1 {
  gap: var(--space-1);
}
.gap-2 {
  gap: var(--space-2);
}
.gap-3 {
  gap: var(--space-3);
}
.gap-4 {
  gap: var(--space-4);
}
.gap-5 {
  gap: var(--space-5);
}
.gap-6 {
  gap: var(--space-6);
}
.gap-7 {
  gap: var(--space-7);
}
.gap-8 {
  gap: var(--space-8);
}
.gap-9 {
  gap: var(--space-9);
}

/*
  Padding
*/
/* paddings based on spacing */
.p1 {
  padding: var(--space-1);
}
.p2 {
  padding: var(--space-2);
}
.p3 {
  padding: var(--space-3);
}
.p4 {
  padding: var(--space-4);
}
.p5 {
  padding: var(--space-5);
}
.p6 {
  padding: var(--space-6);
}
.p7 {
  padding: var(--space-7);
}
.p8 {
  padding: var(--space-8);
}
.p9 {
  padding: var(--space-9);
}

/* responsive padding for containers */
.padding-responsive {
  padding: 0 var(--space-4);

  @media screen and (min-width: 600px) {
    padding: 0 var(--space-5);
  }

  @media screen and (min-width: 960px) {
    padding: 0 var(--space-6);
  }

  @media screen and (min-width: 1368px) {
    padding: 0 var(--space-8);
  }

  @media screen and (min-width: 1920px) {
    padding: 0 var(--space-9);
  }
}

/*
  Margins
*/
/* margins based on spacing */
.m1 {
  margin: var(--space-1);
}
.m2 {
  margin: var(--space-2);
}
.m3 {
  margin: var(--space-3);
}
.m4 {
  margin: var(--space-4);
}
.m5 {
  margin: var(--space-5);
}
.m6 {
  margin: var(--space-6);
}
.m7 {
  margin: var(--space-7);
}
.m8 {
  margin: var(--space-8);
}
.m9 {
  margin: var(--space-9);
}

/* auto margins */
.m-auto {
  margin: 0 auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}

/*
  Hyphen, word-wrap and ellipses -
  https://justmarkup.com/articles/2015-07-31-dealing-with-long-words-in-css/
*/
.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.ellipses {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/*
  Sizes: width and offsets
*/
// Widths
.size1of12 {
  width: calc(100% * (1 / 12));
}
.size2of12 {
  width: calc(100% * (2 / 12));
}
.size3of12 {
  width: calc(100% * (3 / 12));
}
.size4of12 {
  width: calc(100% * (4 / 12));
}
.size5of12 {
  width: calc(100% * (5 / 12));
}
.size6of12 {
  width: calc(100% * (6 / 12));
}
.size7of12 {
  width: calc(100% * (7 / 12));
}
.size8of12 {
  width: calc(100% * (8 / 12));
}
.size9of12 {
  width: calc(100% * (9 / 12));
}
.size10of12 {
  width: calc(100% * (10 / 12));
}
.size11of12 {
  width: calc(100% * (11 / 12));
}
.size12of12 {
  width: calc(100% * (12 / 12));
}
.size1of5 {
  width: calc(100% * (1 / 5));
}
.size2of5 {
  width: calc(100% * (2 / 5));
}
.size3of5 {
  width: calc(100% * (3 / 5));
}
.size4of5 {
  width: calc(100% * (4 / 5));
}
.size5of5 {
  width: calc(100% * (5 / 5));
}

// Offsets
.offset1of12 {
  margin-left: calc(100% * (1 / 12));
}
.offset2of12 {
  margin-left: calc(100% * (2 / 12));
}
.offset3of12 {
  margin-left: calc(100% * (3 / 12));
}
.offset4of12 {
  margin-left: calc(100% * (4 / 12));
}
.offset5of12 {
  margin-left: calc(100% * (5 / 12));
}
.offset6of12 {
  margin-left: calc(100% * (6 / 12));
}
.offset7of12 {
  margin-left: calc(100% * (7 / 12));
}
.offset8of12 {
  margin-left: calc(100% * (8 / 12));
}
.offset9of12 {
  margin-left: calc(100% * (9 / 12));
}
.offset10of12 {
  margin-left: calc(100% * (10 / 12));
}
.offset11of12 {
  margin-left: calc(100% * (11 / 12));
}
.offset12of12 {
  margin-left: calc(100% * (12 / 12));
}
.offset1of5 {
  margin-left: calc(100% * (1 / 5));
}
.offset2of5 {
  margin-left: calc(100% * (2 / 5));
}
.offset3of5 {
  margin-left: calc(100% * (3 / 5));
}
.offset4of5 {
  margin-left: calc(100% * (4 / 5));
}
.offset5of5 {
  margin-left: calc(100% * (5 / 5));
}

.loadingOverlay {
  display: grid;
  color: var(--color-text);
  font-weight: var(--fontWeightMedium);
  touch-action: none;
  user-select: none;
  pointer-events: none;
  z-index: var(--index-backdrop);
}
.loadingMessage {
  margin-left: var(--space-2);
}

.dashSpinner {
  color: rgb(164, 164, 164);
  animation: spin 1.5s linear infinite;

  .circle {
    stroke: currentColor;
    stroke-linecap: round;
    animation: material-dash 1.5s ease-in-out infinite;
  }
}

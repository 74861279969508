.BaseInput {
  min-width: 0;
  width: 100%;
  font-family: var(--fontSans);

  &.no-style .input {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  // focus
  &.focused .label {
    color: var(--color-text);
  }
  &.focused .input,
  &.focused textarea {
    color: var(--color-text);
    box-shadow: inset 0 0 0 1px var(--color-primary);
  }

  & .button {
    --button-height: auto;
    .label {
      color: var(--color-primary);
    }
    &:hover {
      .label {
        color: var(--color-primary-hover);
      }
    }
  }

  .label {
    color: var(--color-text);
    font-weight: var(--fontWeightMedium);
    transition: color var(--duration) var(--ease);
    padding-bottom: 3px;
  }

  .label-tooltip {
    display: inline-block;
    margin-left: 5px;
    top: 2px;
  }

  .input,
  textarea {
    flex: 1 1 auto;
    padding: 5px;
    color: rgba(var(--color-text-rgb), 1);
    border-radius: var(--border-radius);
    border: 0px solid transparent;
    box-shadow: inset 0 0 0 1px rgba(var(--color-shadow-rgb), 1);
    transition: all var(--duration) var(--ease);
    padding-left: 16px;
  }

  textarea {
    padding-top: 15px;
    resize: none;
  }

  &.disabled .input {
    color: var(--color-text-rgb);
    background-color: rgba(var(--color-shadow-rgb), 0.3);
    box-shadow: rgba(var(--color-shadow-rgb), 0.03) 0 1px 1px inset,
      rgba(var(--color-shadow-rgb), 0.06) 0 0 0 1px inset;
    pointer-events: none;
  }

  &.has-errors .input {
    box-shadow: inset 0 0 0 1px var(--color-danger);
  }

  .error-message {
    font-size: 1.4rem;
    margin-top: 5px;
    color: var(--color-danger);
  }

  *::-webkit-input-placeholder {
    color: rgba(var(--color-text-rgb), 0.4);
  }
  *:-moz-placeholder {
    color: rgba(var(--color-text-rgb), 0.4);
  }
  *::-moz-placeholder {
    color: rgba(var(--color-text-rgb), 0.4);
  }
  *:-ms-input-placeholder {
    color: rgba(var(--color-text-rgb), 0.4);
  }
  *::-ms-input-placeholder {
    color: rgba(var(--color-text-rgb), 0.4);
  }
  *::placeholder {
    color: rgba(var(--color-text-rgb), 0.4);
  }
}

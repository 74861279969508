.BaseRangeSlider {
  --thumb-size: 24px;
  --progress: 0%;
  --track-lower: var(--color-primary);
  --track-upper: var(--silver);
  --gradient: var(--track-lower) 0%, var(--track-lower) var(--progress),
    var(--track-upper) var(--progress), var(--track-upper) 100%;
  --output-factor: 0.5;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-height: calc(var(--thumb-size) + var(--space-1));

  .label {
    flex: 1 1 auto;
    width: 100%;
    color: var(--ink);
  }

  .prefix {
    margin-right: 0.8rem;
  }
  .suffix {
    min-width: 2.4rem;
    margin-left: 0.8rem;
    text-align: right;
  }

  .track {
    flex: 1 1 auto;
    align-items: center;
    min-height: 2.8rem;
  }

  .input,
  .input::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .input {
    flex: 1 1 auto;
    height: 6px;
    border-radius: 6px;
    background: linear-gradient(90deg, var(--gradient));
    border: none;
    padding: 0;
    margin: 0;
    outline: none;
    cursor: pointer;

    &:focus::-webkit-slider-thumb {
      border-color: var(--progress-lower);
    }
    &:focus::-moz-range-thumb {
      border-color: var(--progress-lower);
    }
    &:focus::-moz-range-thumb {
      border-color: var(--progress-lower);
    }
  }

  .input::-webkit-slider-thumb {
    width: var(--thumb-size);
    height: var(--thumb-size);
    color: var(--silver);
    background: var(--color-white);
    border: 1px solid var(--silver);
    border-radius: 100%;
    box-shadow: var(--shadow-ring);
    transform: scale(1);
    transition-property: border-color, box-shadow, transform;
    transition-duration: var(--duration);
    transition-timing-function: var(--ease);
    cursor: -webkit-grab;

    &:hover,
    &:focus,
    &:active {
      box-shadow: var(--shadow-ring), var(--shadow-focus);
      outline: none;
    }

    &:active {
      cursor: -webkit-grabbing;
      transform: scale(0.95);
    }
  }

  /* Mozilla styles */
  .input::-moz-range-thumb {
    width: var(--thumb-size);
    height: var(--thumb-size);
    color: var(--silver);
    background: var(--color-white);
    border: 1px solid var(--silver);
    border-radius: 100%;
    box-shadow: var(--shadow-ring);
    transform: scale(1);
    transition-property: border-color, box-shadow, transform;
    transition-duration: var(--duration);
    transition-timing-function: var(--ease);
    cursor: -webkit-grab;

    &:hover,
    &:focus,
    &:active {
      box-shadow: var(--shadow-ring), var(--shadow-focus);
      outline: none;
    }

    &:active {
      cursor: -webkit-grabbing;
      transform: scale(0.95);
    }
  }

  .output {
    position: absolute;
    left: var(--progress);
    bottom: 2.4rem;
    transform: translate3d(calc(-50% - var(--output-factor, 0) * 2.4rem), -2px, 0);
    transition-property: opacity, visibility, transform;
    transition-duration: var(--duration);
    transition-timing-function: var(--ease);
    opacity: 0;
    pointer-events: none;
    visibility: hidden;

    span {
      min-width: 3.2rem;
      height: 3.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-white);
      background-color: var(--color-primary);
      border-radius: 3px;
      padding: 0 0.8rem;
    }
  }

  &:hover .output {
    visibility: visible;
    opacity: 1;
    transform: translate3d(calc(-50% - var(--output-factor, 0) * 2.4rem), -8px, 0);
  }
}

.OrganizationMembers {
  .organizationAccountHeader {
    margin-bottom: 40px;
    max-width: 1000px;
  }
  .button.invite-user-btn {
    font-size: 1.6rem;
    font-weight: var(--fontWeightMedium);
  }
  .header-actions {
    padding: 15px 20px 15px 25px;
    border-bottom: 1px solid #d9e1e2;
    .batch-actions {
      gap: 10px;
      margin-left: 70px;
      .checkbox-all-container {
        display: flex;
        align-items: center;
        margin-right: 24px;
        .trigger-container {
          pointer-events: all;
          svg {
            color: var(--color-text);
          }
        }
      }
      &.actions-disabled {
        .BaseTooltip .trigger-container,
        .delete-users {
          pointer-events: none;
          svg {
            color: #d9e1e2;
          }
        }
      }
      .trigger-container {
        svg {
          color: #a2aaad;
          flex-shrink: 0;
          transition: color var(--duration);
          &:hover,
          &.isActive {
            color: var(--color-primary);
          }
        }
        .icon-toggle.isActive {
          svg {
            color: var(--color-primary);
          }
        }
      }
      .delete-users {
        svg {
          color: #a2aaad;
          transition: color var(--duration);
          &:hover {
            color: var(--color-danger);
          }
        }
      }

      .legend-sorting-item-icon {
        display: inline-flex;
        cursor: pointer;
        padding: 14px 10px 10px 20px;
        vertical-align: sub;
      }
    }
    .inactive-filter {
      label {
        font-size: 1.6rem;
      }
    }
  }
  .table-container {
    width: 100%;
    max-width: 1000px;
    border: 2px solid #f3f3f4;
    background-color: #ffffff;
    margin-bottom: 40px;
    .user-count {
      font-size: 1.6rem;
    }
    .BaseDropdown {
      height: 40px;
    }
    .user-name {
      font-size: 1.6rem;
    }
    .user-email {
      font-size: 1.2rem;
      color: #a2aaad;
    }
    .avatar {
      margin-top: 5px;
      margin-right: 9px;
      width: 32px;
      height: 32px;
    }
    .member-date {
      color: #a2aaad;
      font-size: 1.4rem;
    }
    .hover-reinvite-icon,
    .hover-password-icon {
      padding: 1px 4px 0 0;
      opacity: 0;
      cursor: pointer;
      color: #a2aaad;
      transition: all var(--duration);
      &:hover {
        color: var(--color-primary);
      }
    }
    .ant-table-tbody > tr.ant-table-row:hover {
      .hover-reinvite-icon,
      .hover-password-icon {
        opacity: 1;
      }
    }
    .hover-delete-icon {
      padding: 1px 4px 0 0;
      opacity: 0;
      cursor: pointer;
      color: #a2aaad;
      transition: all var(--duration);
      &:hover {
        color: var(--color-danger);
      }
    }
    .ant-table-tbody > tr.ant-table-row:hover {
      .hover-delete-icon {
        opacity: 1;
      }
    }
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    opacity: 1;
    top: -56px;
    left: 13px;
  }
  .ant-table-selection-column .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }

  .ant-table-column-sorters {
    padding: 0px;
  }
  .ant-table-selection-column .ant-checkbox:hover .ant-checkbox-inner {
    border-color: var(--color-primary);
  }
  .ant-table-selection-column .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }
  .ant-table-selection-column .ant-checkbox-checked .ant-checkbox-inner::after {
    left: 28%;
  }
  .ant-table-selection-column .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--color-primary);
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--color-primary);
  }

  .ant-table-thead > tr > th {
    background: #fff !important;
    border-bottom: none;
    padding: 10px 6px 0 6px;
    text-align: left;
  }
  .ant-table-tbody > tr > td {
    border: none;
    background: #fff;
    padding: 5px 6px;
  }
  .ant-table-tbody > tr:last-child > td {
    border: none;
    background: #fff;
    padding-bottom: 10px;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #fff !important;
    border-color: rgba(0, 0, 0, 0.03);
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #f0f3f3 !important;
  }
  .ant-table-thead {
    .ant-table-cell {
      font-size: 1.4rem;
      color: var(--color-text);
      font-weight: var(--fontWeightBold);
    }
  }

  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: var(--color-primary);
  }

  .ant-dropdown-trigger {
    position: relative;
    top: 2px;
    left: 2px;
  }
}

.checkbox-batch-options {
  padding: 1rem 0px;
  width: 17rem;
  font-size: 1.4rem;
  font-weight: var(--fontWeightMedium);
}
.checkbox-batch-options-item {
  user-select: none;
  cursor: pointer;
  background-color: white;
  color: rgba(var(--color-text-rgb), 0.5);
}
.checkbox-batch-options-item:hover {
  color: var(--color-text);
  background-color: var(--color-accent-hover);
}
.checkbox-batch-options-item-icon {
  display: inline-flex;
  cursor: pointer;
  padding: 14px 10px 10px 15px;
  vertical-align: sub;
}
.checkbox-batch-options-item-label {
  display: inline-flex;
  cursor: pointer;
  padding-right: 5px;
}

.batch-user-types {
  padding: 1rem 0px;
  width: 14rem;
  font-size: 1.4rem;
  font-weight: var(--fontWeightMedium);
  .user-types-item {
    padding: 10px 16px;
    user-select: none;
    cursor: pointer;
    color: rgba(var(--color-text-rgb), 0.5);
  }

  .user-types-item:hover {
    background-color: var(--color-accent-hover);
    color: var(--color-text);
  }

  .user-types-item.selected:hover {
    background-color: var(--color-accent-selected-hover);
  }

  .user-types-item.selected {
    color: var(--color-text);
    background-color: var(--color-accent-hover);
  }

  .user-types-item-label {
    display: inline-flex;
    cursor: pointer;
    padding-right: 5px;
  }
}

.batch-user-status {
  padding: 1rem 0px;
  width: 14rem;
  font-size: 1.4rem;
  font-weight: var(--fontWeightMedium);
  .user-status-item {
    padding: 10px 16px;
    user-select: none;
    cursor: pointer;
    color: rgba(var(--color-text-rgb), 0.5);
  }

  .user-status-item:hover {
    background-color: var(--color-accent-hover);
    color: var(--color-text);
  }

  .user-status-item.selected:hover {
    background-color: var(--color-accent-selected-hover);
  }

  .user-status-item.selected {
    color: var(--color-text);
    background-color: var(--color-accent-hover);
  }

  .user-status-item-icon {
    display: inline-flex;
    cursor: pointer;
    padding: 0px 10px 0px 10px;
    vertical-align: sub;
  }

  .user-status-item-label {
    display: inline-flex;
    cursor: pointer;
    padding-right: 5px;
  }
}

.batch-user-2fa {
  padding: 1rem 0px;
  width: 14rem;
  font-size: 1.4rem;
  font-weight: var(--fontWeightMedium);
  .user-2fa-item {
    padding: 10px 16px;
    user-select: none;
    cursor: pointer;
    color: rgba(var(--color-text-rgb), 0.5);
  }

  .user-2fa-item:hover {
    background-color: var(--color-accent-hover);
    color: var(--color-text);
  }

  .user-2fa-item.selected:hover {
    background-color: var(--color-accent-selected-hover);
  }

  .user-2fa-item.selected {
    color: var(--color-text);
    background-color: var(--color-accent-hover);
  }

  .user-2fa-item-icon {
    display: inline-flex;
    cursor: pointer;
    padding: 0px 10px 0px 10px;
    vertical-align: sub;
  }

  .user-2fa-item-label {
    display: inline-flex;
    cursor: pointer;
    padding-right: 5px;
  }
}

.invite-users-email-dialog {
  .MuiPaper-root {
    border-radius: 0px !important;
  }
  .MuiDialog-container {
    font-family: var(--fontSans);
    .BaseInput {
      min-height: 110px;
    }
    .button.base-button {
      height: 54px;
      width: 320px;
      border-radius: 4px;
      font-size: 1.8rem;
      margin-bottom: 40px;
    }
    .MuiDialogActions-root {
      justify-content: center;
    }
  }
}

.delete-users-email-dialog {
  .MuiTypography-root > div:first-child {
    font-size: 2.2rem;
    margin-bottom: 0;
    svg {
      color: #ff5252;
      margin-right: 15px;
    }
  }
  .MuiDialogContent-root {
    .flex-column {
      gap: 18px;
    }
  }
  .button {
    height: 48px;
    width: 120px;
    border-radius: 4px;
    span {
      font-size: 1.6rem;
    }
  }
  .checkbox-input {
    .checkbox {
      border-color: var(--red);
      margin-right: 10px;
    }
    &.checked .checkbox {
      background-color: var(--red);
    }
  }
  p,
  span {
    font-size: 1.4rem;
    font-weight: var(--fontWeightMedium);
  }
}

.UserDefaults {
  max-width: 560px;
  margin-top: -40px;
  .section {
    padding: 18px 0;
  }
  .section-heading {
    font-size: 1.6rem;
    padding-bottom: 30px;
    margin-bottom: 3px;
    font-weight: var(--fontWeightBold);
    padding: 1px;
  }

  .color-scheme-item:hover {
    background-color: var(--color-accent-hover);
    color: var(--color-text);
  }

  .color-scheme-item {
    background-color: var(--color-background);
    border: 1px solid var(--smoke);
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    padding: 2px 23px 2px 27px;
    user-select: none;
    cursor: pointer;
    font-weight: var(--fontWeightMedium);
    color: rgba(var(--color-text-rgb), 0.5);
    .color-scheme-colors {
      width: 100%;
      height: 24px;
      display: inline-flex;
      align-items: center;
      .color {
        width: 18px;
        height: 18px;
      }
      svg {
        margin-left: 9px;
        transition: color var(--duration);
        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }

  .charts {
    .chart-selectors {
      display: grid;
      left: 5px;
    }
    .split {
      grid-template-areas:
        "top"
        "bottom";
      .Top {
        grid-area: top;
        margin-bottom: 15px;
      }
      .Bottom {
        grid-area: bottom;
      }
    }

    .large-map {
      grid-template-areas:
        "top"
        "middle"
        "bottom";
      .Top {
        grid-area: top;
        margin-bottom: 15px;
      }
      .Middle {
        grid-area: middle;
        margin-bottom: 15px;
      }
      .Bottom {
        grid-area: bottom;
      }
    }

    .horizontal-map {
      grid-template-areas: "left right";
      .Left {
        grid-area: left;
      }
      .Right {
        grid-area: right;
      }
    }

    .all-charts {
      grid-template-areas:
        "top-left top-right"
        "bottom-left bottom-right";
      .TopLeft {
        grid-area: top-left;
      }
      .TopRight {
        grid-area: top-right;
      }
      .BottomLeft {
        grid-area: bottom-left;
      }
      .BottomRight {
        grid-area: bottom-right;
      }
    }
  }

  .project-options,
  .filter-options,
  .layout-selector {
    height: 50px;
  }

  .base-button {
    margin-right: 5px;
  }

  .map-selector {
    height: 75px;
    left: 2px;
    margin-bottom: 15px;
  }

  .focus-tiles {
    .preset {
      display: grid;
      flex-direction: row;
      padding: 5px 15px 15px 2px;
      flex-wrap: wrap;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      grid-column-gap: 5px;
      grid-row-gap: 5px;
      button {
        width: 100%;
        padding: 15px 0;
        font-size: 1.4rem;
        font-weight: var(--fontWeightRegular);
        border-radius: 4px;
      }
      button.primary {
        background-color: var(--color-text);
        font-weight: var(--fontWeightMedium);
      }
    }
    .open {
      background-color: var(--color-accent-hover);
    }
  }
}

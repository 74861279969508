.App {
  height: 100vh;
}

/* page */
.page-enter {
  opacity: 0;
}
.page-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.page-exit {
  opacity: 1;
}
.page-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.Toastify__toast {
  border-radius: 4px;
  min-height: 42px;
  color: #fff;
  font-family: var(--fontSans);
}
.Toastify__toast--success {
  background-color: var(--color-primary);
}
.Toastify__toast--error {
  background-color: var(--color-danger);
}
.Toastify__toast--info {
  background-color: #ffbd00;
}

.user-menu .ant-popover-inner-content,
.normalize-popover .ant-popover-inner-content,
.group-by-popover .ant-popover-inner-content,
.popover-no-padding .ant-popover-inner-content {
  padding: 0;
  margin: 0;
}

/* Making the select dropdown appear on top of screenshot overlay and modal */
.ant-select-dropdown.screenshot-selector {
  z-index: auto;
}

.source-icon {
  transform: scale(1.2);
}

.activity-action-icon {
  transform: scale(1.2);
}

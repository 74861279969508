.subscriptionInfo {
  display: flex;
  flex-direction: column;
  max-width: 670px;
  gap: 32px;
  font-size: 1.6rem;
  .base-divider {
    margin: 15px 0 25px 0;
  }
  .info-card {
    padding: 30px;
    background-color: var(--color-white);
    .sub-heading {
      font-size: 1.8rem;
      color: #a2aaad;
      font-weight: var(--fontWeightMedium);
    }
    .content {
      display: flex;
      .column {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        .label {
          font-size: 1.4rem;
          color: #1e2029;
          padding-bottom: 10px;
        }
        .value {
          font-size: 2rem;
          font-weight: var(--fontWeightBold);
        }
      }
    }
  }
}

.ColorPicker {
  width: 3rem;
  height: 3rem;
  display: inline-flex;
  border-radius: 2px;
  transition-property: border-radius, box-shadow;
  transition-duration: var(--duration-short);
  transition-timing-function: var(--ease);
  overflow: hidden;
  user-select: none;
  cursor: pointer;

  // change border-radius in direction the picker is opening
  &.isOpen.top-end,
  &.isOpen.left-end,
  &.isOpen.left-center {
    border-top-left-radius: 3px;
  }
  &.isOpen.top-start,
  &.isOpen.right-end,
  &.isOpen.right-center {
    border-top-right-radius: 3px;
  }
  &.isOpen.bottom-end,
  &.isOpen.left-start {
    border-bottom-left-radius: 3px;
  }
  &.isOpen.bottom-start,
  &.isOpen.right-start {
    border-bottom-right-radius: 3px;
  }

  &.hasAnimations {
    &:hover,
    &:focus,
    &:active {
      border-color: rgba(var(--color-shadow-rgb), 0.06);
      box-shadow: rgba(var(--color-shadow-rgb), 0.1) 0 1px 2px,
        rgba(var(--color-shadow-rgb), 0.1) 0 0 0 1px, var(--shadow-focus);
    }
    &:active {
      transform: scale(0.96);
    }
  }

  .icon {
    filter: invert(0.1) grayscale(1);
  }
  .flex-centered {
    background-size: 100% 100%, auto;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &.atFolderLevel {
    width: unset;
    height: unset;
    border-color: unset;
    box-shadow: unset;
  }
}

.ant-popover {
  &.ant-popconfirm {
    &.set-color-confirm {
      z-index: 1035;
    }
  }
}

.avatar {
  --avatar-size: 3rem;
  width: var(--avatar-size);
  height: var(--avatar-size);
  background-color: var(--color-primary-1);
  box-shadow: var(--color-background) 0px 0px 0px 2px;
  margin: 2px;
  letter-spacing: 0.5px;
  cursor: pointer;

  &.has-focus {
    box-shadow: 0 0 0 2px rgba(95, 99, 104, 0.4);
  }

  .avatar-initials {
    color: white;
    font-size: calc(var(--avatar-size) / 2.1);
    font-weight: var(--fontWeightMedium);
  }

  // appearance
  &.circle,
  &.circle .avatar-initials {
    border-radius: 50%;
    > img {
      border-radius: 50%;
    }
  }
  &.rounded,
  &.rounded .avatar-initials {
    border-radius: var(--border-radius);
  }
  &.square,
  &.square .avatar-initials {
    border-radius: 0;
  }

  // sizes
  &.xsmall {
    --avatar-size: 1.6rem;
  }
  &.small {
    --avatar-size: 2.2rem;
  }
  &.medium {
    --avatar-size: 4.4rem;
  }
  &.large {
    --avatar-size: 6rem;
  }
}

.aurora-login {
  .container .reset-form {
    width: 100%;
    display: grid;
    gap: 45px;
  }
  .container .reset-form .fieldset {
    width: 100%;
    display: grid;
    gap: 60px;
  }
}

.signup-form {
  width: 100%;
  display: grid;
  grid-gap: 30px;
  gap: 20px;

  .integration-container {
    gap: 30px;
  }
  .back-btn {
    margin-top: 40px;
    color: var(--color-text);
    font-size: 1.6rem;
    font-weight: var(--fontWeightBold);
    svg {
      vertical-align: sub;
      margin-right: 4px;
      top: 2px;
    }
  }
  .BaseDropdown {
    height: 46px;
  }
  .checkbox-input {
    margin-bottom: 25px;
  }

  .signup.label {
    color: var(--color-text);
    font-weight: var(--fontWeightMedium);
    transition: color var(--duration) var(--ease);
    padding-bottom: 3px;
    text-align: left;
  }
}

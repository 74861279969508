.BaseMenu {
  z-index: 1030;
}

.filter-activity {
  .section-heading {
    display: block;
    width: 100%;
    background-color: var(--color-background-header);
    padding: 0;
    color: var(--color-text-header);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .sub-heading {
      text-align: left;
      margin: 0;
    }
  }
}
.sort-by {
  display: flex;
  justify-content: space-between;
  padding: 2px 15px 5px 12px;
  button {
    width: 24px;
    height: 24px;
    padding: 0;
  }
  .focus-menu {
    display: flex;
    .icon-toggle {
      color: #a2aaad;
      &:hover {
        color: var(--color-text);
      }
    }
  }
  .batch-options-container {
    padding: 4px 0px 0 0;
    display: flex;
    .trigger-container {
      .icon-toggle {
        padding: 0px;
        top: 6px;
      }
    }
  }
}
.groupby-container {
  max-width: 100%;
  grid-template-rows: auto auto minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  justify-items: space-evenly;
  grid-gap: 2px;
  .preset {
    display: grid;
    flex-direction: row;
    padding: 5px 15px 15px 15px;
    flex-wrap: wrap;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    button {
      width: 100%;
      padding: 15px 0;
      font-size: 1.4rem;
      font-weight: var(--fontWeightRegular);
      border-radius: 4px;
    }
    button.primary {
      background-color: var(--color-text);
      font-weight: var(--fontWeightMedium);
    }
  }
  .group-trigger {
    width: 100%;
  }
  .group-trigger.isSelected {
    background-color: var(--color-text);
    font-weight: var(--fontWeightMedium);
    color: #fff;
  }
  .gb {
    .selected-groupby {
      color: teal;
    }
    .bin-settings-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px 15px;
      h3 {
        margin: 0;
      }
    }
    .bin {
      display: grid;
      grid-template-rows: auto minmax(0, 1fr);
      grid-template-columns: minmax(0, 1fr);
      grid-gap: 5px;
      padding: 0 15px;
      .bin-settings {
        display: grid;
        column-gap: 9px;
        grid-template-rows: minmax(0, 1fr);
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        .label {
          font-size: 1.2rem;
          line-height: 24px;
        }
      }
      .BaseInput {
        font-size: 1.4rem;
      }
      input {
        height: 30px;
      }
      .min-size.button {
        font-weight: var(--fontWeightRegular);
        justify-content: flex-end;
        --button-height: 1.6rem;
        &:hover {
          .label {
            color: var(--color-primary);
          }
        }
        .label {
          color: #b5b5b5;
          display: inline-flex;
          gap: 2px;
          margin-right: 0px;
          font-size: 1.2rem;
        }
      }
    }
    .update-group-container {
      width: 100%;
      padding: 8px 15px;
      button {
        width: 100%;
        height: 30px;
        font-size: 1.4rem;
        border-radius: 4px;
      }
    }
    .base-button.primary {
      background-color: var(--color-primary);
      font-weight: var(--fontWeightMedium);
      &:hover {
        background-color: var(--color-text);
      }
    }
  }
}

.legend-container {
  max-height: 100%;
  width: 100%;
  overflow-y: hidden;
  display: grid;
  grid-template-rows: auto auto minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  .search-bar {
    padding-top: 7px;
  }
}

.active-filter {
  max-height: 100%;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  overflow-y: auto;
  padding: 0 0 0 10px;
  .active-filter-header {
    padding: 0 30px 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 5px;
  }
  .section-heading {
    margin: 0;
    .BaseDropdown {
      width: 200px;
    }
  }
}

.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 0px;
  border-radius: 4px;
  box-shadow: 0 1px 16px 0 rgba(130, 169, 217, 0.15);
  color: var(--color-text);
  font-size: 14px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: rgba(var(--color-text-rgb), 0.5);
  cursor: pointer;
  font-weight: 500;
  line-height: 1.5;
  padding: 8px 20px;
  text-align: inherit;
  white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: var(--color-text);
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
  content: "▶";
  display: inline-block;
  position: absolute;
  right: 7px;
}

.legend-item.selected {
  color: var(--color-text);
  background-color: rgba(var(--color-text-rgb), 0.1);
}

.focus-popover {
  transition: none;
  .ant-popover-inner-content {
    padding: 0;
  }
}

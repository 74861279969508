.export-button {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 200px;
  flex-shrink: 0;
  background-color: white;
  border: none;
  background: none;
}

.export-button:hover {
  background-color: var(--color-accent-hover);
  color: var(--color-text);
}

.export-container {
  font-size: 1.4rem;
  font-weight: var(--fontWeightMedium);
  position: relative;
  &.hidden {
    display: none;
  }
}

.button-text {
  // align-items: center;
  // margin-left: 5px;
  display: inline-flex;
  cursor: pointer;
  padding-top: 14px;
  padding-bottom: 13px;
  padding-left: 10px;
  vertical-align: sub;
}
.rdg {
  .rdg-checkbox {
    position: relative;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: 0.15rem solid rgba(var(--color-text-rgb), 0.4);
    border-radius: 3px;
    transition-property: background-color border-color;
    transition-duration: var(--duration);
    transition-timing-function: var(--ease);
  }

  .rdg-cell-selected {
    box-shadow: inset 0 0 0 2px var(--color-primary);
  }

  .rdg-row-selected {
    background-color: transparent;
  }

  .rdg-row:hover {
    background-color: var(--row-hover-background-color);
  }

  .rdg-cell {
    box-shadow: none !important;
    border-color: rgba(var(--color-text-rgb), 0.1);
  }

  input:focus + .rdg-checkbox {
    border-color: var(--color-primary);
  }

  input:checked + .rdg-checkbox {
    border-color: var(--color-primary);
    background-color: var(--color-primary);
    box-shadow: none;
  }

  input:checked + .rdg-checkbox::after {
    content: "";
    display: block;
    width: 6px;
    height: 10px;
    border: 2px solid #fff;
    border-width: 0 2px 2px 0;
    position: absolute;
    top: 15%;
    left: 35%;
    transform: rotate(45deg);
  }
}

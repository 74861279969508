.mfaForm {
  display: flex;
  flex-direction: column;
  max-width: 580px;
  gap: 30px;
  font-size: 1.6rem;
  .mfa-label {
    font-weight: var(--fontWeightMedium);
  }
  .BaseTooltip {
    display: inline-flex;
  }
  .mfa-container {
    display: flex;
    align-items: center;
    border: 1px solid #d9e1e2;
    gap: 25px;
    border-radius: 21.5px;
    padding: 10px 15px;
    align-self: flex-start;
  }
  .deactivate-account {
    .button {
      font-size: 1.6rem;
      font-weight: var(--fontWeightMedium);
      color: var(--color-danger);
      &:hover {
        color: var(--color-danger-hover);
      }
    }
  }
}

.deactivate-account-dialog {
  div.flex-column {
    gap: 32px;
  }
  p {
    font-size: 1.6rem;
  }
  .BaseInput {
    min-height: 110px;
    width: 100%;
  }
  .button.base-button {
    height: 54px;
    width: 320px;
    border-radius: 4px;
    font-size: 1.8rem;
    margin: 0 auto;
    margin-bottom: 40px;
  }
}

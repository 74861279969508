.BaseTooltip {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: var(--fontWeightMedium);
  z-index: 100;
  max-width: 100%;
  .content {
    position: absolute;
    top: 100%;
    left: 50%;
    z-index: 10;
    background: var(--color-primary);
    border-radius: var(--border-radius);
    color: var(--color-white);
    padding: 3px;
    transform-origin: 50% 0;
    transform: translate3d(-50%, 0.6rem, 0);
    /* transition-property: opacity transform;
      transition-duration: var(--duration);
      transition-timing-function: var(--ease); */
    opacity: 0;
    pointer-events: none;
    user-select: none;
    visibility: hidden;
    white-space: nowrap;
  }
  .content.visible {
    opacity: 0.9;
    /* transform: translate3d(-50%, 0.8rem, 0) scale(1); */
    visibility: visible;
  }
  /* TODO: extract arrow styles out for use with floating containers */
  .content::before {
    position: absolute;
    content: "";
    top: 0;
    left: 50%;
    transform-origin: center;
    transform: translate3d(-50%, -100%, 0);
    border-width: 0.6rem;
    border-style: solid;
    border-color: transparent transparent var(--color-primary) transparent;
  }
}

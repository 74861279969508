.slider {
  position: absolute;
  top: 0;
  left: 0;
  height: 5px;
  width: 100%;
  overflow-x: hidden;
  z-index: 10000;
}
.slider.hidden {
  display: none;
}

.line {
  position: absolute;
  opacity: 0.4;
  background: #00c7b1;
  width: 150%;
  height: 5px;
}

.subline {
  position: absolute;
  background: #00c7b1;
  height: 5px;
}
.inc {
  animation: increase 2s infinite;
}
.dec {
  animation: decrease 2s 0.5s infinite;
}

@keyframes increase {
  from {
    left: -5%;
    width: 5%;
  }
  to {
    left: 130%;
    width: 100%;
  }
}
@keyframes decrease {
  from {
    left: -80%;
    width: 80%;
  }
  to {
    left: 110%;
    width: 10%;
  }
}

.dark {
  --badgeColorDefault: var(--snow);
  --badgeBackgroundDefault: var(--ink);

  --badgeColorImportant: var(--color-primary);
  --badgeBackgroundImportant: var(--darkRed);

  --badgeColorPrimary: var(--ink);
  --badgeBackgroundPrimary: var(--color-primary);

  --badgeColorAdded: var(--color-primary);
  --badgeBackgroundAdded: var(--darkerGreen);

  --badgeColorRemoved: var(--color-primary);
  --badgeBackgroundRemoved: var(--darkerRed);
}

:root {
  --badgeColorDefault: var(--charcoal);
  --badgeBackgroundDefault: transparent;

  --badgeColorImportant: var(--snow);
  --badgeBackgroundImportant: var(--darkRed);

  --badgeColorPrimary: var(--snow);
  --badgeBackgroundPrimary: var(--color-primary);

  --badgeColorAdded: var(--darkGreen);
  --badgeBackgroundAdded: var(--tintGreen);

  --badgeColorRemoved: var(--darkRed);
  --badgeBackgroundRemoved: var(--tintRed);
}

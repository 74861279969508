.dashboard-menu {
  padding-top: 0;
  .ant-popover-inner-content {
    padding: 0;
  }
}
.ant-menu-submenu-popup {
  .ant-menu-sub {
    .ant-menu-item {
      border-bottom: 4px solid transparent;
      color: var(--color-text);
    }
    .ant-menu-item:hover,
    .ant-menu-item-selected {
      background-color: inherit;
      border-bottom: 4px solid var(--color-primary);
    }
    .ant-menu-item-selected {
      font-weight: var(--fontWeightBold);
    }
  }
}

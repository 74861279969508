.organizationForm {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: minmax(0, 450px) auto;
  grid-template-rows: minmax(0, 1fr);
  gap: 45px;
  font-size: 1.6rem;
  .form-container {
    gap: 30px;
    display: grid;
  }
  .section-header {
    width: 100%;
    h3 {
      font-size: 1.8rem;
      color: #a2aaad;
      font-weight: var(--fontWeightMedium);
    }
  }
  .label {
    font-size: 1.6rem;
    font-weight: var(--fontWeightMedium);
  }
  .input {
    height: 46px;
  }
  .trigger-container {
    width: 100%;
    .BaseDropdown {
      height: 46px;
    }
  }
  .button-container {
    display: inline-flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 45px;
    .button {
      width: 320px;
      height: 54px;
      border-radius: 4px;
      span {
        font-size: 1.8rem;
        font-weight: var(--fontWeightBold);
      }
    }
  }
  .company-logo {
    width: 217px;
    height: 217px;
    border: 1px solid #d9e1e2;
    background-color: #ffffff;
    margin-top: 50px;
  }
  .BaseInput + .section-header {
    margin-top: 20px;
  }
  .trigger-container ~ .error-message {
    height: 0px;
    font-size: 1.4rem;
    margin-top: -16px;
    color: var(--color-danger);
  }
}

.AccountSecurity {
  max-width: 450px;
  .button.primary {
    height: 46px;
    font-size: 1.8rem;
    font-weight: var(--fontWeightBold);
    width: 200px;
    border-radius: 4px;
  }
  .section {
    padding: 33px 0;
  }
  .section-heading {
    font-size: 1.6rem;
    padding: 0;
    padding-bottom: 22px;
    font-weight: var(--fontWeightMedium);
  }
  .company-info {
    gap: 30px;
    .company-logo {
      width: 102px;
      height: 102px;
      border-radius: 50%;
      background-color: #b5b5b5;
      color: var(--color-white);
    }
    .company-name-label {
      padding-top: 10px;
      font-size: 1.6rem;
      font-weight: var(--fontWeightMedium);
    }
    .company-name {
      font-size: 2rem;
      font-weight: var(--fontWeightBold);
    }
  }
  .user-email {
    padding: 50px 0 40px 0;
  }
  .BaseInput {
    label {
      font-size: 1.6rem;
      font-weight: var(--fontWeightMedium);
    }
  }
  .input {
    height: 46px;
  }
  .user-phone {
    strong {
      font-size: 1.6rem;
    }
    .BaseInput {
      .label {
        padding-bottom: 9px;
      }
    }
    .BaseTooltip {
      display: inline-flex;
      top: 2px;
    }
    .button.subtle {
      font-size: 1.6rem;
      border-radius: 4px;
      font-weight: var(--fontWeightMedium);
    }
    .button.primary {
      margin-top: 30px;
    }
  }
  .user-password {
    gap: 20px;
    display: flex;
    flex-direction: column;
    .section-heading {
      padding: 0;
    }
    .button.subtle {
      font-size: 1.6rem;
      font-weight: var(--fontWeightMedium);
    }
  }
  .deactivate-account {
    .button {
      font-size: 1.6rem;
      font-weight: var(--fontWeightMedium);
      color: var(--color-danger);
      &:hover {
        color: var(--color-danger-hover);
      }
    }
  }
}

@import "./scss/normalize";
@import "./scss/multiselect";
@import "./scss/variables";
@import "./scss/base";
@import "./scss/classes";
@import "./scss/typography";
@import "./scss/keyframes";
@import "./scss/transitions";

*,
*::before,
*::after {
  position: relative;
  box-sizing: border-box;
  outline: none;
}

/*
    16px = 1em,
  ∴ 1px = 0.625em
  Setting root font-size to 62.5% will give us relative sizing option:
    1rem = 10px
    1.6rem = 16px and so on...
*/
html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

html {
  // makes 1rem = 10px
  font-size: 62.5%;
  line-height: var(--line-height-default);
}

body {
  display: grid;
  color: var(--color-text);
  background: var(--color-background);
  font-synthesis: none;
  font-style: normal;
  font-size: var(--fontSize);
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  font-feature-settings: "kern", "liga", "lnum", "tnum", "zero";
  -moz-font-feature-settings: "kern", "liga", "lnum", "tnum", "zero";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration-skip-ink: auto;
  overflow: hidden overlay;
  scroll-behavior: smooth;

  &.lock {
    overflow: hidden;
  }
}

/*
  Customize scrollbar and selection
*/
::-webkit-scrollbar {
  width: var(--scrollbar-width) !important;
  height: var(--scrollbar-height) !important;
}
::-webkit-scrollbar-thumb {
  background-color: var(--color-scrollbar-thumb);
  border-radius: 0;
}
::-webkit-scrollbar-track {
  background: var(--color-scrollbar-track);
}

::selection {
  color: var(--color-selection) !important;
  background-color: var(--color-selection-background) !important;
}

pre {
  font-family: var(--fontMono);
  font-size: 1.2rem;
}

// prevents the layout-shift when,
// the browser swaps the fallback-font with custom-font
@supports (font-size-adjust: 1) {
  article {
    font-size-adjust: 0.507;
  }
}

.hidden {
  display: none;
}

/* Popper */
div[data-popper-placement] {
  display: grid;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: var(--border-radius);
  background-color: white;
  z-index: var(--index-top);
}

.ant-modal-mask {
  z-index: 1002;
}
.ant-modal-wrap {
  /*wrap is above mask*/
  z-index: 1003;
}
.user-defined-modal-root,
.add-dashboard-modal-root,
.copy-dashboard-modal-root,
.project-input-modal-root,
.screenshot-modal-root {
  z-index: 2000;
  .ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
    border-color: var(--color-primary);
  }
  .ant-btn:hover {
    background: var(--color-primary);
    border: var(--color-primary);
    color: white;
  }
  .ant-btn-primary {
    background: var(--color-text);
    border: var(--color-text);
  }
  .ant-btn {
    border-radius: 5px;
    min-width: 80px;
  }
  .ant-modal-content {
    .ant-upload {
      padding: 0;
    }
  }
  button[disabled],
  button[disabled]:hover {
    background-color: #a2aaad;
    color: white;
  }
}
.add-dashboard-tooltip-root {
  z-index: 2001;
}

.screenshot-modal-root {
  pointer-events: none;
  z-index: unset !important;
  .ant-modal-mask {
    background: transparent;
    pointer-events: none;
  }
  .ant-modal {
    .react-draggable {
      pointer-events: all;
    }
  }
  .ant-modal-body {
    padding: 0;
  }
}

.screenshot-popover,
.normalize-select-popover {
  .ant-popover-inner-content {
    padding: 0;
  }
}
.modal-select,
.screenshot-selector {
  .ant-select-item-option-selected {
    color: white;
    background-color: var(--color-primary) !important;
  }
}

.valnav-tw-importer-popover {
  .ant-popover-message-title {
    padding: 0;
  }
}

.modal-tooltip,
.modal-select {
  z-index: 2001;
}

.react-draggable {
  cursor: move;
  pointer-events: auto;
}

.project-delete-popconfirm {
  z-index: 2000;
}

.small-remove-button {
  width: 24px;
  height: 24px;

  // Causes vertical misalignment
  font-size: 0px;

  // use text color
  color: var(--color-icon);

  &:hover {
    color: var(--color-danger);
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}

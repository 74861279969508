.Range-Input {
  width: 100%;
  display: flex;
  padding: 28px 0 0;
  .range-track {
    height: 4px;
    width: 100%;
    background-color: #a2aaad;
  }
  .range-thumb {
    height: 10px;
    width: 10px;
    background-color: var(--color-white);
    border-radius: 50%;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.44);
  }
  .range-label {
    position: absolute;
    top: -28px;
    color: var(--color-text);
    font-weight: var(--fontWeightMedium);
    font-size: 1.4rem;
    padding: "5px";
  }
}

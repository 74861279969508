p {
  max-width: 100%;
  text-align: left;
  font-size: 16px;
}

a,
.link {
  color: #1890ff;
  text-decoration: underline;
}

h1 {
  margin: 0px;
}

h5 {
  margin: 0px;
}

img {
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

ul {
  list-style: disc inside;
}

ul > li {
  display: list-item;
}

ul > li > ul {
  margin-left: 15px;
  list-style-type: circle;
}

.OrganizationDataSources {
  .organizationAccountHeader {
    margin-bottom: 35px;
  }
  .table-container {
    max-width: 700px;
    border: 2px solid #f3f3f4;
    background-color: #ffffff;
    margin-bottom: 40px;

    padding: 20px 23px 26px 23px;
    .hover-actions {
      opacity: 0;
      cursor: pointer;
      transition: all var(--duration);
      svg {
        flex-shrink: 0;
        color: #a2aaad;
        transition: color var(--duration);
        &:hover {
          color: var(--color-primary);
        }
        &.delete-source:hover {
          color: var(--color-danger);
        }
      }
    }
    .status-row {
      align-items: center;
      gap: 10px;
      color: #a2aaad;
      font-size: 1.4rem;
      font-weight: var(--fontWeightMedium);
      & > .flex-row {
        gap: 10px;
        align-items: center;
      }
      &.inProgress {
        .BaseSlideToggle {
          background-color: #ffbd00;
        }
        span {
          font-size: 1.4rem;
        }
      }
      &.failed {
        .BaseSlideToggle {
          background-color: var(--color-danger);
        }
        span {
          font-size: 1.4rem;
        }
      }
      & > .failed-verification {
        font-weight: var(--fontWeightMedium);
        span:last-child {
          font-size: 1.2rem;
          color: #ff5252;
        }
      }
    }
    .add-source {
      .button {
        font-size: 1.6rem;
        font-weight: var(--fontWeightMedium);
      }
      svg {
        top: 4px;
        margin-right: 5px;
      }
    }
    .ant-table-tbody > tr.ant-table-row:hover {
      .hover-actions {
        opacity: 1;
      }
    }
    .ant-table-tbody > tr.ant-table-row.row-expanded {
      .ant-table-cell {
        background: #f0f3f3;
      }
      & > .ant-table-cell:first-child {
        font-weight: var(--fontWeightBold);
      }
    }
    td.ant-table-cell {
      font-size: 1.6rem;
    }
    tbody tr > td:last-child {
      background: #fff !important;
    }
  }

  .button.base-button.primary {
    height: 54px;
    width: 220px;
    border-radius: 4px;
    font-size: 1.8rem;
    margin-bottom: 40px;
  }

  .availableSources {
    margin-bottom: 40px;
    .subtitle {
      font-size: 1.6rem;
      margin: 10px 0 20px 0;
    }
    .data-source-card {
      border: 1px solid #f3f3f4;
      background-color: #ffffff;
      .content {
        padding: 30px;
        font-size: 1.6rem;
        .data-source-name {
          font-size: 2rem;
          font-weight: var(--fontWeightBold);
        }
        .data-source-description {
          font-weight: var(--fontWeightMedium);
        }
        & > .flex-row:first-child {
          margin-bottom: 25px;
        }
        .button.base-button {
          font-weight: var(--fontWeightMedium);
        }
      }
    }
  }

  .ant-table-column-sorter .active {
    color: var(--color-primary);
  }

  .categories-container {
    .categories-matrix {
      display: grid;
      grid-template-columns: 1fr 1fr;
      .category {
        border-bottom: 1px solid #f0f3f3;
      }
      .category:nth-child(even) {
        padding: 10px 10px 10px 22px;
      }
      .category:nth-child(odd) {
        border-right: 1px solid #f0f3f3;
        padding: 10px 16px 10px 0;
      }
      .category:last-child,
      .category:nth-last-of-type(-n + 2):not(:nth-child(even)) {
        border-bottom: none;
      }
    }
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid #d9e1e2;
  }
  .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 1.8rem;
    color: #a2aaad;
  }
  .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn:hover {
    color: var(--color-text);
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--color-text);
    font-weight: var(--fontWeightBold);
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 3px;
  }
  .ant-tabs-ink-bar {
    background: var(--color-primary);
  }

  .ant-table-column-sorters {
    padding: 0px;
  }
  .ant-table-selection-column .ant-checkbox:hover .ant-checkbox-inner {
    border-color: var(--color-primary);
  }
  .ant-table-selection-column .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }
  .ant-table-selection-column .ant-checkbox-checked .ant-checkbox-inner::after {
    left: 28%;
  }
  .ant-table-selection-column .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--color-primary);
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--color-primary);
  }

  table tr td.ant-table-selection-column {
    padding-left: 25px;
    padding-right: 0px;
  }
  .ant-table-thead > tr > th {
    background: #fff !important;
    border-bottom: none;
    padding: 10px 16px 0 16px;
  }
  .ant-table-tbody > tr > td {
    border: none;
    background: #fff;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #fff !important;
    border-color: rgba(0, 0, 0, 0.03);
  }
  .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background: #f0f3f3 !important;
  }
  .ant-table-thead {
    .ant-table-cell {
      font-size: 1.4rem;
      color: var(--color-text);
      font-weight: var(--fontWeightBold);
    }
  }
  .ant-spin-nested-loading > div > .ant-spin {
    top: 60px;
  }
}

.edit-data-source-dialog,
.add-data-source-dialog {
  .MuiDialogContent-root {
    padding-top: 0px !important;
  }
  .container {
    padding-bottom: 40px;
    font-size: 1.6rem;
    & > div {
      padding: 30px 0;
    }
    .flex-column > p {
      margin-bottom: 22px;
    }
    .input {
      height: 46px;
      margin-bottom: 15px;
    }
    .server-input {
      .input {
        width: 290px;
      }
    }
    .button.primary {
      height: 54px;
      font-size: 1.6rem;
      font-weight: var(--fontWeightBold);
      width: 200px;
      border-radius: 4px;
    }
  }
  .password-row {
    padding: 33px 0;
    border-top: 1px solid #d9e1e2;
    border-bottom: 1px solid #d9e1e2;
  }
  .server-row {
    border-bottom: 1px solid #d9e1e2;
  }
}

.add-data-source-dialog {
  .button.primary {
    height: 54px;
    font-size: 1.6rem;
    font-weight: var(--fontWeightBold);
    width: 200px;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  .container {
    padding-bottom: 0px;
    & > div {
      padding: 15px 0;
    }
    .flex-column > p {
      margin-bottom: 0px;
      font-size: 1.6rem;
      font-weight: var(--fontWeightMedium);
    }
    .flex-row > .flex-column {
      width: 100%;
    }
    .server-input {
      .input {
        width: 100%;
      }
    }
  }
}

.remove-data-source-dialog {
  .MuiTypography-root > div:first-child {
    font-size: 1.8rem;
    margin-bottom: 0;
    svg {
      color: #ff5252;
      margin-right: 15px;
    }
  }
  .MuiDialogContent-root {
    .flex-column {
      gap: 18px;
    }
  }
  .button {
    height: 48px;
    width: 200px;
    margin-top: 20px;
    border-radius: 4px;
    span {
      font-size: 1.6rem;
    }
  }
  .checkbox-input {
    .checkbox {
      border-color: var(--red);
      margin-right: 10px;
    }
    &.checked .checkbox {
      background-color: var(--red);
    }
  }
  p,
  span {
    font-size: 1.4rem;
    font-weight: var(--fontWeightMedium);
  }
}

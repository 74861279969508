.aurora-login {
  .container .two-factor-form {
    width: 100%;
    display: grid;
    gap: 45px;
    max-width: 405px;
    margin: 0 auto;
    p {
      font-size: 1.6rem;
      color: var(--color-text);
    }
    .code-container {
      display: flex;
      justify-content: center;
      gap: 12px;
      .BaseInput {
        max-width: 54px;
        .input {
          font-size: 1.8rem;
          font-weight: var(--fontWeightBold);
          text-align: center;
          padding-left: 5px;
        }
      }
    }
    .request-link {
      .button {
        text-decoration: underline;
      }
    }
    .login-btn {
      .button {
        border-radius: 4px;
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
}

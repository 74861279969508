.indicator-container {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -123px;
  transform: translateY(-50%);
  z-index: 3;
  .content {
    background-color: rgb(186, 189, 194);
    color: #4b4b4b;
    border-radius: 5px;
    padding: 2px 60px;
  }
}

.errorMessage,
.messageIcon {
  color: var(--darkRed);
}

.errorMessage {
  margin-top: 3px;
}

.messageIcon svg {
  height: 20px;
  width: 20px;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  pointer-events: none;
  vertical-align: bottom;
}

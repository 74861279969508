@import "./base/badge";

.dark,
.dark:root {
  img {
    opacity: 0.8;
  }
  --color-primary: #1d384e;
  --color-text: white;
  --color-text-rgb: 255, 255, 255;
  --color-primary-rgb: 200, 200, 200;
  --color-text-active: var(--smoke);
  --color-text-hover: var(--smoke);
  --colorDropdownItemSelected: white;
  --color-background: #020101;
  --color-background-complementary: #141516;
  --color-surface-01: #1d1d1d;
  --color-surface-02: #222222;
  --color-surface-03: #252525;
  --color-surface-04: #3d3d3d;
  --color-surface-14: #333333;
  --color-chart-background: var(--charcoal);
  --color-chart-border: var(--iron);
  --color-btn: #a2aaad;

  --color-selection-background: var(--color-accent);

  --dropdownListShadow: 0 1px 3px black, -1px 4px 6px black;
  /*
    scrollbar
  */
  --color-scrollbar-thumb: var(--concrete);
  --color-scrollbar-track: var(--ink);

  /*
    vue-grid-layout and item
  */
  --gridItemPlaceholderBorder: var(--color-white);
  --gridItemPlaceholderBackground: var(--ink);

  /* ---------------- ATOMS ---------------- */
  /*
    avatar
  */
  --avatarColor: var(--silver);
  --avatarBackground: var(--ink);

  /*
    Icon, IconButton and IconToggle
  */
  --iconActiveColor: var(--color-primary-1);
  --iconDefaultColor: var(--slate);
  --iconDisabledColor: var(--ink);
  --iconHoverColor: var(--smoke);
}

:root {
  /*
    Transition duration
  */
  --duration: 220ms;
  --duration-long: 500ms;
  --duration-short: 150ms;

  /*
    Easing
  */
  --ease: cubic-bezier(0.25, 0.5, 0.25, 1);
  --ease-bounce: cubic-bezier(0.5, -0.1, 0.1, 1.25);
  --ease-hover: cubic-bezier(0.4, 0, 0.2, 1);

  /*
    Spacing: in multiple of 4
  */
  --space-0: 0;
  --space-025: 1px;
  --space-05: 2px;
  --space-1: 4px;
  --space-2: 8px;
  --space-3: 12px;
  --space-4: 16px;
  --space-5: 20px;
  --space-6: 24px;
  --space-7: 36px;
  --space-8: 48px;
  --space-9: 64px;

  /*
    Density scale:
    0: for default view
    -1: comfortable view
    -2: compact view
  */
  --density-scale: 0;

  /*
    Border radius
  */
  --border-radius: 4px;

  /*
    Font size, family and other properties
  */
  --fontZoom: 1.4;
  --fontSize: calc(var(--fontZoom) * 1rem);

  --fontSans: "Bai Jamjuree";
  --fontSerif: "Noto Serif JP";
  --fontRound: "Quicksand";
  --fontMono: "Roboto Mono", monospace;

  --fontFamily: var(--fontSans), -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  --fontWeightLight: 200;
  --fontWeightRegular: 400;
  --fontWeightMedium: 500;
  --fontWeightBold: 700;
  --fontWeightBlack: 800;

  --letter-spacing-default: 0;
  --letter-spacing-tight: -0.5px;
  --letter-spacing-loose: 0.5px;

  // https://hugogiraudel.com/2020/05/18/using-calc-to-figure-out-optimal-line-height
  // --line-height-default: calc(4px + 2ex + 4px);
  --line-height-default: 1.5;
  --line-height-compact: 1;

  /*
    TODO: Create a SASS function to get tint, light, dark and darker shades
    for a color.
  */
  --white: white;
  --color-white: white;
  --black: #0f0f0f;
  --transparent: #0f0f0f00;

  /*
    McDaniel hue values:
    207 - Blue
    214 - Charcoal
  */
  --hue-primary: 207;
  --hue-complementary: calc(var(--hue-primary) + 180);
  --hue-text: 214;

  /*
  McDaniel Colors
  */
  --color-primary-1: rgb(37, 55, 70);
  --color-primary-2: rgb(164, 188, 194);
  --color-primary-3: rgb(83, 86, 90);
  --color-primary-4: rgb(217, 225, 226);
  --color-secondary-1: rgb(181, 129, 80);
  --color-secondary-2: rgb(220, 134, 51);
  --color-secondary-3: rgb(154, 185, 173);
  --color-secondary-4: rgb(152, 72, 86);

  --color-accent: rgb(217, 225, 226);
  --color-accent-hover: rgba(217, 225, 226, 0.513);
  --color-accent-selected-hover: rgba(217, 225, 226, 0.603);

  --color-action-button: #a2aaad;

  /*
    Extra light greys
  */
  --snow: #f9fafc;
  --mist: #f0f3f3;
  --fog: #eef1f6;
  --cloud: #e3e6e8;
  /*
    Light greys
  */
  --smoke: #e0e6ed;
  --vape: #d3dce6;
  --fume: #c0ccda;
  --gas: #b3bac5;
  /*
    Dark greys
  */
  --silver: #abbece;
  --flint: #9aa9b7;
  --lead: #8395a5;
  --graphite: #748ea4;
  --concrete: #69849b;
  --slate: #5d7a92;
  --ink: #41586c;
  --iron: #3c4858;
  --charcoal: #273444;
  --tar: #1f2d3d;
  --squid: #091e42;

  /*
    Red
  */
  --darkerRed: #c7000d;
  --darkRed: #e41023;
  --red: #ff5252;
  --red-rgb: rgb(255, 82, 82);
  --lightRed: #f46d6d;
  --lighterRed: #ffccd1;
  --tintRed: #ffebee;

  /*
    Green
  */
  --darkerGreen: #006f10;
  --darkGreen: #00a032;
  --green: #00c349;
  --lightGreen: #5ad783;
  --lighterGreen: #beedca;
  --tintGreen: #e4f8ea;

  /*
    Blue
  */
  --darkBlue: hsl(208, 86%, 40%);
  --lightBlue: hsl(208, 86%, 66%);
  --blue: #1287ed;
  --blueTint: hsl(208, 90%, 96%);
  --blue-rgb: 18, 135, 237;

  /*
    Teal
  */
  --darkTeal: #005a5c;
  --lightTeal: #00b5b8;
  --teal: #00878a;
  --tealTint: #e7f3f3;

  /*
    Sand
  */
  --darkSand: #231c15;
  --lightSand: #c8b4a2;
  --sand: #a38062;
  --sandTint: #f7f5f2;

  /*
    Cyan
  */
  --darkCyan: #009ceb;
  --lightCyan: #85d6ff;
  --cyan: #1fb4ff;
  --cyanTint: #ebf8ff;
  --cyan-rgb: 31, 180, 255;

  /*
    Pink
  */
  --darkPink: #ff1a53;
  --lightPink: #ff7a9c;
  --pink: #ff4775;
  --pinkTint: #ffebf0;

  /*
    Yellow
  */
  --darkYellow: #fab700;
  --lightYellow: #ffd561;
  --yellow: #ffc72e;
  --yellowTint: #fffaeb;

  /*
    Orange
  */
  --darkerOrange: #d1461c;
  --darkOrange: #ed7b20;
  --orange: #ffa500;
  --lightOrange: #faae52;
  --orangeTint: #fef2e0;
  --orange-rgb: 209, 70, 28;

  /*
    Purple
  */
  --darkPurple: #6d2eea;
  --lightPurple: #ae8af4;
  --purple: #8d5def;
  --purpleTint: #f3f0fa;

  /*
    McDaniel colors
  */
  --mcd-black: #141415;
  --mcd-blue: #025f9c;
  --mcd-charcoal: #53565a;
  --mcd-dark-blue: #253746;
  --mcd-green: #9ab9ad;
  --mcd-light-blue: #a4bcc2;
  --mcd-orange: #e86525;
  --mcd-tan: #d3bc8d;

  // rgb values
  --mcd-black-rgb: 20, 20, 21;
  --mcd-blue-rgb: 2, 95, 156;
  --mcd-charcoal-rgb: 69, 71, 74;
  --mcd-dark-blue-rgb: 37, 55, 70;
  --mcd-green-rgb: 154, 185, 173;
  --mcd-light-blue-rgb: 164, 188, 194;
  --mcd-orange-rgb: 220, 134, 51;
  --mcd-tan-rgb: 211, 188, 141;

  /*
    Primary and text color
    TODO: add secondary, accent, success, warning and error colors
  */
  --color-primary: #00c7b1;
  --color-primary-hover: #006459;
  --color-icon: #a2aaad;
  --color-text: #041c2c;
  --color-text-active: #53565a;
  --color-text-hover: #53565a;
  --color-text-selected: white;
  --color-text-selected-hover: white;
  --color-shadow: #d9e1e2;
  --color-background: white;
  --color-surface-01: #fcfcfc;
  --color-surface-02: #fbfbfb;
  --color-surface-03: #fbfafa;
  --color-surface-04: #d9d9d9;
  --color-surface-05: #1e2029;
  --color-surface-14: rgb(245, 245, 245);
  --color-background-complementary: #f5f5f6;
  --color-danger: #ff5252;
  --color-danger-hover: #b71c1c;

  --color-primary-rgb: 0, 199, 177;
  --color-text-rgb: 4, 28, 44;
  --color-shadow-rgb: 217, 225, 226;

  // primary-color variants
  --color-primary-03: rgba(var(--color-primary-rgb), 0.03);
  --color-primary-06: rgba(var(--color-primary-rgb), 0.06);
  --color-primary-10: rgba(var(--color-primary-rgb), 0.1);
  --color-primary-20: rgba(var(--color-primary-rgb), 0.2);
  --color-primary-40: rgba(var(--color-primary-rgb), 0.4);
  --color-primary-60: rgba(var(--color-primary-rgb), 0.6);
  --color-primary-80: rgba(var(--color-primary-rgb), 0.8);
  // text-color variants
  --color-text-03: rgba(var(--color-text-rgb), 0.03);
  --color-text-06: rgba(var(--color-text-rgb), 0.06);
  --color-text-10: rgba(var(--color-text-rgb), 0.1);
  --color-text-20: rgba(var(--color-text-rgb), 0.2);
  --color-text-40: rgba(var(--color-text-rgb), 0.4);
  --color-text-50: rgba(var(--color-text-rgb), 0.5);
  --color-text-60: rgba(var(--color-text-rgb), 0.6);
  --color-text-80: rgba(var(--color-text-rgb), 0.8);

  /*
    colors from PDP-assistant
  */
  --oProductColor: #186b2d;
  --wProductColor: #025aff;
  --gProductColor: #fd0e1a;
  --cProductColor: #1bce0c;
  --color-product-c-rgb: 27, 206, 12;
  --color-product-g-rgb: 253, 14, 26;
  --color-product-o-rgb: 24, 107, 45;
  --color-product-w-rgb: 2, 90, 255;

  /*
    focus colors
  */
  --color-focus-primary: rgba(var(--blue-rgb), 0.12);
  --color-focus-default: rgba(var(--color-shadow-rgb), 0.06);
  --color-focus-error: hsla(345, 62%, 46%, 0.15);
  --color-focus-success: hsla(145, 40%, 75%, 0.4);
  --color-focus-warning: hsla(45, 79%, 58%, 0.4);

  /*
    box-shadows
  */
  // For all focusable elements
  --shadow-focus: 0 0 0 1px rgb(173, 216, 230), 0 0 0 4px rgba(173, 216, 230, 0.4);

  // For drawer
  --shadow-drawer: rgba(var(--color-shadow-rgb), 0.3) 0 1px 3px 0,
    rgba(var(--color-shadow-rgb), 0.15) 0 4px 8px 3px;

  // For ring element on range-slider and slide-toggle
  --shadow-ring: rgba(var(--color-shadow-rgb), 0.2) 0 1px 2px -1px,
    rgba(var(--color-shadow-rgb), 0.12) 0 1px 2px 0, var(--fog) 0 -2px 0 0 inset;

  // For popup boxes
  --shadow-popup: rgba(var(--color-shadow-rgb), 0.06) 0 0 0 1px,
    rgba(var(--color-shadow-rgb), 0.08) 0 1px 3px,
    rgba(var(--color-shadow-rgb), 0.1) 0 2px 5px,
    rgba(var(--color-shadow-rgb), 0.12) 0 9px 27px -6px;

  --shadow-popup-secondary: rgba(var(--color-shadow-rgb), 0.03) 0px 0px 0px 1px,
    rgba(var(--color-shadow-rgb), 0.06) 0px 3px 6px,
    rgba(var(--color-shadow-rgb), 0.12) 0px 9px 24px;

  /*
    scrollbar
  */
  --scrollbar-width: 8px;
  --scrollbar-height: 8px;
  --color-scrollbar-thumb: #a4bcc2;
  --color-scrollbar-track: #d9e1e2;

  /*
    selection
  */
  --color-selection: var(--color-primary);
  --color-selection-background: lightblue;

  /*
    z-indices
  */
  --index-bottom: -1;
  --index-0: 0;
  --index-1: 1;
  --index-backdrop: 10;
  --index-drawer: 11;
  --index-fullscreen: 100;
  --index-popup: 101;
  --index-tooltip: 1000;
  --index-top: 999999999999;

  /*
    Flexbox
  */
  --order-first: -1;
  --order-last: 999999999 !important;

  /*
    vue-grid-layout and item
  */
  --gridItemPlaceholderBorder: var(--tar);
  --gridItemPlaceholderBackground: var(--smoke);
  --gridItemResizeHandle: url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 22 22" xml:space="preserve"><style>.st0{fill: rgb(171, 190, 206)}</style><path class="st0" d="M20 20h2v2h-2zM20 16h2v2h-2zM20 12h2v2h-2zM20 8h2v2h-2zM20 4h2v2h-2zM16 20h2v2h-2zM16 16h2v2h-2zM16 12h2v2h-2zM16 8h2v2h-2zM12 20h2v2h-2zM12 16h2v2h-2zM12 12h2v2h-2zM8 20h2v2H8zM8 16h2v2H8zM4 20h2v2H4zM0 20h2v2H0zM4 16h2v2H4zM8 12h2v2H8zM12 8h2v2h-2zM16 4h2v2h-2zM20 0h2v2h-2z"/></svg>');

  /* ---------- ATOMS ----------- */
  /*
    Avatar
  */
  --avatarColor: var(--flint);
  --avatarBackground: var(--cloud);

  /*
    Dropdown List
  */
  --colorDropdownItemDefault: var(--flint);
  --colorDropdownItemHover: var(--color-text);
  --colorDropdownItemHoverBackground: var(--mist);
  --colorDropdownItemSelected: var(--color-primary);
  --colorDropdownItemSelectedBackground: var(--fog);
  --colorDropdownItemBorder: var(--fume);
  --dropdownListShadow: 0 1px 3px var(--smoke), -1px 4px 6px var(--cloud);

  /*
    Icon, IconButton and IconToggle
  */
  --iconDefaultColor: var(--flint);
  --iconHoverColor: var(--iron);
  --iconActiveColor: var(--color-primary);
  --iconDisabledColor: var(--fume);

  /*
    SlideToggle
  */
  --slideToggleDefaultColor: var(--color-text-20);
  --slideToggleActiveColor: var(--mcd-blue);
  --slideToggleDisabledColor: var(--mist);
  --slideToggleDisabledRingColor: var(--lead);

  /*
    Tabs
  */
  --tabListBGColor: #f5f5fa;
  --tabBorderColor: #dddfed;

  /*
    Input Fields
  */
  --inputDefaultBGColor: rgba(84, 94, 111, 0.04);
  --inputInsetShadow: inset 0 1px 0 rgba(84, 94, 111, 0.06);

  /* --------- MOLECULES ---------- */

  /*
    Notifications
  */
  --notification: var(--iron);
  --notificationBackground: var(--color-white);
  --notificationBar: var(--lead);
  --notificationIcon: var(--iron);

  /* --------- ORGANISMS ---------- */

  /*
    Chart
  */
  --color-chart-background: var(--color-white);
  --color-chart-border: var(--fog);
  --chart-toolbar-height: 48px;

  /*
    Toolbar & navbar
  */
  --toolbar-height: 4.5rem;
  --toolbar-background-color: var(--transparent);
  --toolbar-border-color: rgba(var(--color-text-rgb), 0.06);

  /*
    Sidebar
  */
  --sidebarWidth: 28rem;
  --logo-width: 73px;
  --navbar-height: 45px;
  --navbar-column-gap: var(--space-3); // 16px
  --activity-bar-width: 60px;
  --workspace-select-width: 240px;
  --chart-toolbar-height: 48px;
}

.base-divider {
  color: rgba(var(--color-text-rgb), 0.1);
}
// horizontal
.base-divider.horizontal {
  width: 100%;
  height: 1px;
  background: currentColor;
  margin: var(--space-1) 0;
}

// vertical
.base-divider.vertical {
  height: 100%;
  width: 1px;
  background: currentColor;
  margin: 0 var(--space-5);
}

// make the ends of divider transparent
.base-divider.sharp.horizontal {
  background: linear-gradient(
    to right,
    rgba(var(--color-text-rgb), 0),
    currentColor,
    rgba(var(--color-text-rgb), 0)
  );
}
.base-divider.sharp.vertical {
  background: linear-gradient(
    rgba(var(--color-text-rgb), 0),
    currentColor,
    rgba(var(--color-text-rgb), 0)
  );
}

.multiphase-axis-input {
  .YYYY-MM {
    width: 100px;
  }
  &.xAxis.xMin {
    .rdtPicker {
      bottom: 102%;
      left: -29px;
    }
  }
  &.xAxis.xMax {
    .rdtPicker {
      bottom: 102%;
      right: 0;
    }

    &.YYYY-MM {
      bottom: 4px;
      right: 10px;
    }
  }
  svg {
    width: 16px;
    color: #5b6770;
    margin-left: 6px;
    margin-right: 4px;
    cursor: pointer;
    transition: all var(--duration);

    &:hover {
      color: var(--color-primary);
    }
  }

  .input {
    width: 100%;
    flex: 1 1 auto;
    color: var(--color-text);
    background-color: var(--white);
    border: none;
    font-size: 12px;
    padding: 4px;

    &::selection {
      color: var(--color-text) !important;
    }
  }

  .rdtPicker {
    min-width: 210px;
    td.rdtMonth,
    td.rdtYear {
      height: 40px;
    }
  }
  & > .rdt {
    width: 100%;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
}
